/* eslint-disable no-underscore-dangle */
// eslint-disable-next-line import/no-unresolved
import 'vite/modulepreload-polyfill';
import 'client/pages/gallery/gallery.less';
import 'intersection-observer'; // Polyfill for unsupported browsers

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import qs from 'query-string';
import { AnyAction } from 'redux';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { RecoilRoot } from 'recoil';
import {
    Hydrate,
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query';
import { AnalyticsContext } from 'client/contexts/gallery/AnalyticsContext';
import { getLogger } from 'client/utils/gallery/logger';
import { instrumenter } from 'client/utils/instrumentation';
import { Analytics } from 'client/utils/gallery/analytics';
import { configureStore } from 'client/store';
import { enableDebugMode, getExperienceType } from 'client/store/debug';
import { galleryImpressionsTrackingSelector } from 'client/store/analytics';
import { AnalyticsNames, EXPERIENCE_TYPE } from 'shared/constants';
import { AuthProvider } from 'client/contexts/AuthContext';
import { Router } from 'client/pages/gallery/Router';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

const initialState = (window as any).GALLERY.__INITIAL_STATE__;
const dehydratedState = (window as any).GALLERY.__REACT_QUERY_STATE__;
const trackingData = (window as any).GALLERY.__TRACKING_DATA__;

const { tracking = true } = qs.parse(window.location.search, { parseBooleans: true });
const analytics = new Analytics(trackingData, getLogger(), !!tracking);

const store = configureStore(analytics, initialState);
const state = store.getState();
const quickViewDesignID = state.debug.quickViewId;

// Extract a list of ids and feature flags to send to New Relic as custom attributes
const featureFlags = state.experimentation?.rawExperiments
    ? JSON.parse(state.experimentation.rawExperiments)
        .map((exp: Gallery.ABExperimentation.GalleryExperiment) => exp.FeatureFlag)
    : '';

const experienceType = getExperienceType(state);

hydrate(
    <Provider store={store}>
        <QueryClientProvider client={queryClient}>
            <Hydrate state={dehydratedState}>
                <RecoilRoot>
                    <AuthProvider
                        locale={state.config.locale}
                        signoutRedirectUrl={window.location.href}
                    >
                        <AnalyticsContext.Provider value={analytics}>
                            <Router />
                            <ReactQueryDevtools initialIsOpen={false} />
                        </AnalyticsContext.Provider>
                    </AuthProvider>
                </RecoilRoot>
            </Hydrate>
        </QueryClientProvider>
    </Provider>,
    document.getElementById('root'),
);

// page load analytics
analytics.trackNavigation();
// don't record the page view if we have a quickView QSP
if (!quickViewDesignID) {
    analytics.trackImpression(galleryImpressionsTrackingSelector(state, analytics, AnalyticsNames.DesignTile));
}

instrumenter.setPageView('gallery', {
    locale: state.config.locale,
    entityCount: state.paging.totalEntities,
    galleryName: state.config.name,
    galleryId: state.config.galleryId,
    productKey: state.config.productKey,
    featureFlag: featureFlags,
    quickViewId: quickViewDesignID?.toString(),
    experienceType: experienceType || EXPERIENCE_TYPE.GALLERY,
});

// enable debug mode
(window as any).GALLERY.debug = (): AnyAction => store.dispatch(enableDebugMode());

if ((window as any).Cypress) {
    (window as any).store = store;
}

if ('scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual';
}
