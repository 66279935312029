import { useMemo } from 'react';
import { batch, useDispatch } from 'react-redux';
import { Button, ButtonProps } from '@vp/swan';
import { usePersonalizationFlyoutOpen } from 'src/client/components/Gallery/Header/Personalization/hooks';
import { useTranslations } from 'client/hooks/useTranslations';
import { useRecoilValue } from 'recoil';
import { selectedPhotosState } from 'src/client/atoms/selectedPhotosAtom';
import { customImageDataUpdate, logoAppliedUpdate } from 'client/store/personalization/actions';
import { contentUpdate } from 'client/store/content';
import { ANALYTICS_EVENT_ACTIONS } from 'shared/constants';
import { useAnalytics } from 'client/hooks/gallery/useAnalytics';

export const PersonalizationImageModalPreviewButton = (props: Omit<ButtonProps, 'ref'> & { selectedPhotosAlreadyApplied: boolean}): JSX.Element => {
    const { selectedPhotosAlreadyApplied } = props;
    const selectedPhotos = useRecoilValue(selectedPhotosState);
    const localize = useTranslations();
    const dispatch = useDispatch();
    const analytics = useAnalytics();
    const [, setUploadModalOpen] = usePersonalizationFlyoutOpen();

    const applySelectedPhotosText = useMemo(() => {
        const numPhotos = selectedPhotos.length;

        if (numPhotos > 1) {
            return localize('PersonalizationModalPluralPreviewImageButtonText').replace('{{number}}', `${numPhotos}`);
        }

        if (numPhotos === 1) {
            return localize('PersonalizationModalSingularPreviewImageButtonText');
        }

        if (numPhotos === 0 && !selectedPhotosAlreadyApplied) {
            return localize('PersonalizationModalClearPreviewButtonText');
        }

        return localize('PersonalizationModalGenericPreviewImageButtonText');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPhotos.length]);

    const handleApplySelectedPhotos = (): void => {
        batch(() => {
            analytics.trackEvent({
                action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
                eventLabel: 'Personalization modal images applied',
                eventDetail: `Personalization modal images applied`,
                ...analytics.getPageProperties(),
            });
            dispatch(customImageDataUpdate(selectedPhotos));
            dispatch(logoAppliedUpdate(!!selectedPhotos.length));
            dispatch(contentUpdate());
        });
        setUploadModalOpen(false);
    };

    return (
        <Button
            aria-label={applySelectedPhotosText}
            className="apply-uploads-button"
            skin="primary"
            onClick={handleApplySelectedPhotos}
            {...props}
        >
            {applySelectedPhotosText}
        </Button>
    );
};
