import {
    ReactElement, ReactNode, useCallback, useMemo, useRef,
} from 'react';
import { useSelector } from 'react-redux';
import {
    Accordion,
    Checkbox,
    Collapsible,
    CollapsibleContent,
    CollapsibleSummary,
    CollapsibleSummaryButton,
    ColorSwatches,
    SelectionSet,
    StandardTileSwatches,
    Typography,
} from '@vp/swan';
import { i18nColorName } from '@design-stack-vista/i18n-color-name';
import { getLocaleSelector } from '~/client/store/config';
import { LinkWithQuery } from '~/client/components/common/LinkWithQuery';
import { TemplateTile } from '~/client/components/common/TemplateTile';
import { Skeleton } from '~/client/components/common/Skeleton';
import { TemplateTileHeader } from '~/client/components/common/TemplateTile/components/TemplateTileHeader';
import { TemplateTileContent } from '~/client/components/common/TemplateTile/components/TemplateTileContent';
import { TemplateTileTitle } from '~/client/components/common/TemplateTile/components/TemplateTileTitle';
import { TemplateTileImage } from '~/client/components/common/TemplateTile/components/TemplateTileImage';
import { TemplateTilePricing } from '~/client/components/common/TemplateTile/components/TemplateTilePricing';
import { TemplateTileColorSwatch } from '~/client/components/common/TemplateTile/components/TemplateTileColorSwatch';
import { useFavorite } from '~/client/components/Gallery/NewDesignTile/hooks/useFavorite';
import { SpacingBlock } from '~/client/components/common/PricingDisplay/SpacingBlock';
import { useColorSwatches } from '~/client/components/Gallery/NewDesignTile/hooks/useColorSwatches';
import { NewDesignProps } from '~/client/components/Gallery/NewDesignTile/interface';
import { usePricing } from '~/client/components/Gallery/NewDesignTile/hooks/usePricing';
import { useGalleryParams } from '~/client/hooks/useGalleryParams';
import { useImage } from '~/client/components/Gallery/NewDesignTile/hooks/useImage';
import { DismissableAlertBox } from '~/client/components/common/DismissableAlertBox';

export const NewDesignTile = ({
    imageProps,
    colorSwatches,
    handleColorSwatchChange,
    market,
    pricingPresentationType,
    productOptionsByProductKey,
    quantity,
    differentialPrice,
    hideVatMessage,
    hideShippingMessageInVatMessage,
    selectedProductOptions,
    pricingPresentationHTMLModifier = (html: string): string => (html),
    tileEntity,
    selectedDesignId,
    entityId,
    favoriteId,
    previewType,
    noFollow,
    currentDesignId,
}: NewDesignProps): JSX.Element => {
    const locale = useSelector(getLocaleSelector);

    // Get Favorite button params
    const {
        isFavorite,
        onClick,
        ariaLabel,
        errorMessage,
        onRequestDismiss,
        dismissVisuallyHiddenLabel,
    } = useFavorite({
        entityId, previewType, colorSwatches, favoriteId,
    });

    // Get Preview Image params
    const { segment } = useGalleryParams();

    const {
        imgRef,
        showSpinner,
        alt,
        imageSrc,
        imageSrcSet,
        loading,
        onError,
        onLoad,
        accessibilityText,
        isLargePreview,
    } = useImage(imageProps);

    const segmentPath = useMemo(() => segment?.join('/'), [segment]);
    const linkProps = useMemo(() => ({
        to: `${segmentPath}/qv/${tileEntity.designId}`,
        state: { designId: currentDesignId },
        noFollow,
    }), [currentDesignId, segmentPath, tileEntity.designId, noFollow]);

    const renderLink = useCallback((props: {children?: ReactNode }): ReactElement => (
        <LinkWithQuery {...linkProps}>
            {props.children}
        </LinkWithQuery>
    ), [linkProps]);

    // Get and handle Color Swatches information
    const contentRef = useRef<HTMLDivElement>(null);
    const {
        shouldShowColorSwatches,
        shouldShowShowMoreButton,
        onColorSwatchChangeWrapper,
    } = useColorSwatches(colorSwatches.length, contentRef, handleColorSwatchChange);

    // Get Pricing data
    const {
        sanitizedPricingHtml,
        lines,
        templateTileTitle,
        shouldRenderSpacingBlock,
        isLoading,
    } = usePricing({
        tileEntity,
        differentialPrice,
        pricingPresentationType,
        market,
        quantity,
        hideVatMessage,
        hideShippingMessageInVatMessage,
        selectedProductOptions,
        productOptionsByProductKey,
        pricingPresentationHTMLModifier,
    });

    return (
        <TemplateTile>
            <TemplateTileHeader>
                <>
                    {errorMessage && (
                    <DismissableAlertBox
                        toast
                        className="template-favorite-button-alert-box"
                        dismissVisuallyHiddenLabel={dismissVisuallyHiddenLabel}
                        skin="error"
                        onRequestDismiss={onRequestDismiss}
                    >
                        {errorMessage}
                    </DismissableAlertBox>
                    )}
                    <Checkbox
                        aria-label={ariaLabel}
                        checked={isFavorite}
                        skin="favorite"
                        onClick={onClick}
                    />
                </>
            </TemplateTileHeader>
            <TemplateTileImage
                accessibilityText={accessibilityText}
                alt={alt}
                isLargePreview={isLargePreview}
                loading={loading}
                ref={imgRef}
                renderWrapper={renderLink}
                showSpinner={showSpinner}
                src={imageSrc}
                srcSet={imageSrcSet}
                onError={onError}
                onLoad={onLoad}
            />
            <TemplateTileContent>
                {shouldShowColorSwatches && (
                    <Accordion mb={0} skin="color-swatches">
                        <Collapsible>
                            {shouldShowShowMoreButton && (
                            <CollapsibleSummary>
                                <CollapsibleSummaryButton>
                                    <Typography>
                                        {colorSwatches.length}
                                    </Typography>
                                </CollapsibleSummaryButton>
                            </CollapsibleSummary>
                            )}
                            <CollapsibleContent>
                                <StandardTileSwatches>
                                    <SelectionSet
                                        ref={contentRef}
                                        selectedValue={selectedDesignId}
                                        onSelectedValueChange={onColorSwatchChangeWrapper}
                                    >
                                        <ColorSwatches>
                                            {colorSwatches.map(({ color = '', designId }) => {
                                                const title = color ? i18nColorName(color, { culture: locale, colorPalette: 'gallery' }) : '';

                                                return (
                                                    <TemplateTileColorSwatch
                                                        color={color}
                                                        designId={designId}
                                                        key={designId}
                                                        title={title}
                                                    />
                                                );
                                            })}
                                        </ColorSwatches>
                                    </SelectionSet>
                                </StandardTileSwatches>
                            </CollapsibleContent>
                        </Collapsible>
                    </Accordion>
                )}
                <TemplateTileTitle>
                    {templateTileTitle}
                </TemplateTileTitle>
                <TemplateTilePricing>
                    {isLoading && (
                    <span className="price-block">
                        <Skeleton />
                    </span>
                    )}
                    <Typography
                        className="price-block"
                        component="span"
                        dangerouslySetInnerHTML={{ __html: sanitizedPricingHtml }}
                    />
                    {shouldRenderSpacingBlock && <SpacingBlock lines={lines} />}
                </TemplateTilePricing>
            </TemplateTileContent>
        </TemplateTile>
    );
};
