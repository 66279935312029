/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
import config from 'config';
import fastq from 'fastq';
import { v4 as uuid } from 'uuid';
import vpTracking, { InitData, Tracking } from '@vp/tracking';
import { get, set } from 'es-cookie';
import { Logger } from '@vp/js-logger';

import { history } from 'client/utils/history';
import { getSelectedFilters } from 'client/store/analytics/reducer';
import {
    getGalleryIdSelector,
    getGalleryNameSelector,
    getH1Title,
    getMpvid,
    getProductKey,
    getProductVersion,
    getForcedRankingStrategySelector,
    getNaturalRankingStrategySelector,
} from 'client/store/config';
import {
    ANALYTICS_CATEGORY, ANALYTICS_EVENT_ACTIONS, DESIGN_CREATION_TYPE, EXPERIENCE_TYPE, PRODUCT_OPTIONS_PRODUCT_UPDATE,
} from 'shared/constants';
import { fireImpression } from '@vp/ab-impression';
import { getExperienceType } from 'client/store/debug';
import { analyticsOnError } from 'client/utils/analyticsLogging';
import { trackAttributeSelected, trackProductOptionsSelected } from '@vp/product-options-tracking';

const GALLERY_IMPRESSION_EVENT_ENTITIES_MAX_CHUNK_SIZE = 24;
const GALLERY_IMPRESSION_EVENT_ENTITIES_MAX_COUNT = 96;

function getChunks<T>(arr: T[], size: number): T[][] {
    return Array.from({
        length: Math.ceil(arr.length / size),
    }, (_, i) => arr.slice(i * size, i * size + size));
}

/**
 * Analytics provides the core functionality for tracking capabilities.
 * For information on the events tracked in gallery, see https://vistaprint.atlassian.net/wiki/spaces/GAL/pages/135628536/Gallery+Tracking+Analytics
 */
export class Analytics implements Gallery.Analytics.IAnalytics {
    private trackingLabel = 'Gallery page view';

    private galleryId: string | undefined;

    private galleryName: string | undefined;

    private hydrated = false;

    private internalGalleryId: number | undefined;

    private h1Title: string | undefined;

    private tracking: Tracking | undefined;

    private queue!: fastq.queue<() => void>;

    private _mpvId: string | undefined;

    private _productKey: string | undefined;

    private _productVersion: number | null | undefined;

    private _state: State.GlobalState | undefined;

    private _experienceType: string | undefined;

    public get mpvId(): string {
        return this._mpvId ? this._mpvId : '';
    }

    public set mpvId(value: string) {
        this._mpvId = value;
    }

    public get productKey(): string {
        return this._productKey ? this._productKey : '';
    }

    public set productKey(value: string) {
        this._productKey = value;
    }

    public get productVersion(): number | null {
        return typeof this._productVersion === 'number' ? this._productVersion : null;
    }

    public set productVersion(value: number | null) {
        this._productVersion = value;
    }

    public get state(): State.GlobalState {
        return this._state ? this._state : {} as State.GlobalState;
    }

    public set state(value: State.GlobalState) {
        this._state = value;
    }

    public set experienceType(value: string) {
        this._experienceType = value;
    }

    public get experienceType(): string {
        return this._experienceType ? this._experienceType : EXPERIENCE_TYPE.GALLERY;
    }

    constructor(trackingData?: InitData, logger?: Logger, enabled = true) {
        if (enabled) {
            this.initialize(trackingData, logger);
        }
    }

    initialize(trackingData?: InitData, logger?: Logger): void {
        this.queue = fastq<() => void>((task, cb) => {
            try {
                cb(null, task());
            } catch (e) {
                cb(e as Error);
            }
        }, 1);
        this.queue.pause();
        this.queue.error((e, task) => {
            if (e) {
                logger?.error({ message: `queueing task failed` }, e, { task: task.toString() });
            }
        });

        window.addEventListener('onbeforeunload', () => this.queue.killAndDrain());
        window.addEventListener('DOMContentLoaded', () => {
            if (trackingData) {
                vpTracking.initWithData(trackingData, {
                    suppressReloadOnConsentSubmit: true,
                    onError: analyticsOnError,
                });
                this.tracking = vpTracking.getTracking();
                this.queue.resume();
            }
        });
    }

    public hydrate(state: State.GlobalState): void {
        this.h1Title = getH1Title(state);
        this.productKey = getProductKey(state);
        this.productVersion = getProductVersion(state);
        this.mpvId = getMpvid(state);
        this.galleryName = getGalleryNameSelector(state);
        this.galleryId = getGalleryNameSelector(state);
        this.internalGalleryId = getGalleryIdSelector(state);
        this.state = state;
        this.hydrated = true;
        this.experienceType = getExperienceType(state) || EXPERIENCE_TYPE.GALLERY;
    }

    /**
     * Session step tracks each time a user sees a "new page" of gallery
     * (basically every gallery interaction that changes what combos are displayed)
     */
    public getIncrementedSessionStep(): number {
        // we start sessionStep at 0, but increment later so set it to -1 here
        let sessionStep = parseInt(get('sessionStep') || '-1', 10);

        sessionStep += 1;
        set('sessionStep', sessionStep.toString());

        return sessionStep;
    }

    /**
     * Hooks into history to add the tracking that needs to happen when the url changes
     * Also logs the initial navigation action
     */
    public trackNavigation(): void {
        if (!history) {
            return;
        }

        history.listen(() => {
            this.trackPage();
            this.trackProductViewed();
        });

        this.trackPage();
        this.trackProductViewed();
    }

    private getPageName(mpvId?: string): string {
        return `${mpvId ? `${mpvId}:` : ''}${config.client.pageName}`;
    }

    public getPageProperties(): Gallery.Analytics.PageProperties {
        return {
            pageSection: config.client.pageName,
            pageStage: 'Design',
            pageName: this.getPageName(this.mpvId),
        };
    }

    public trackPage(): void {
        if (!this.hydrated) {
            return;
        }

        const { mpvId } = this;
        const properties = {
            coreProductKeys: [this.productKey],
            mpvIds: [this.mpvId],
            ...this.getPageProperties(),
        };

        if (this.queue) {
            this.queue.push(() => this.tracking?.page(this.getPageName(mpvId), properties, {}));
        }
    }

    public trackProductViewed(): void {
        if (!this.hydrated) {
            return;
        }

        const properties = {
            label: this.trackingLabel,
            product_id: this.mpvId,
            name: this.h1Title,
            core_product_id: this.productKey,
            ...this.getPageProperties(),

        } as Gallery.Analytics.ProductViewed;

        if (this.queue) {
            this.queue.push(() => this.tracking?.track(ANALYTICS_EVENT_ACTIONS.PRODUCT_VIEWED, properties, {}));
        }
    }

    public trackImpression(properties: Gallery.Analytics.GalleryImpressions): void {
        if (!this.hydrated) {
            return;
        }

        const allProperties = {
            refinements: getSelectedFilters(this.state),
            experienceType: this.experienceType,
            ...properties,
            impressionStitchId: uuid(),
        };

        const trackEventTitle = 'Gallery Impression';

        if (this.queue) {
            // in case of empty designs list
            if (!allProperties.entities.length) {
                this.queue.push(() => this.tracking?.track(trackEventTitle, {
                    ...allProperties,
                    entities: [],
                    pieceId: 0,
                    piecesNum: 1,
                }, {}));

                return;
            }

            // Splitting dynamic and static entities into different chunks is temporary
            // Once data products properly handle dynamic, these can be merged
            const groupedEntities = allProperties.entities.reduce((entities, entity) => {
                if (entity.designCreationType === DESIGN_CREATION_TYPE.DYNAMIC) {
                    entities.dynamicEntities.push(entity);
                } else {
                    entities.staticEntities.push(entity);
                }

                return entities;
            }, {
                dynamicEntities: [] as Gallery.Analytics.AnalyticsEntity[],
                staticEntities: [] as Gallery.Analytics.AnalyticsEntity[],
            });

            const staticChunks = getChunks(
                groupedEntities.staticEntities.slice(0, GALLERY_IMPRESSION_EVENT_ENTITIES_MAX_COUNT),
                GALLERY_IMPRESSION_EVENT_ENTITIES_MAX_CHUNK_SIZE,
            );

            const dynamicChunks = getChunks(
                groupedEntities.dynamicEntities.slice(0, GALLERY_IMPRESSION_EVENT_ENTITIES_MAX_COUNT),
                GALLERY_IMPRESSION_EVENT_ENTITIES_MAX_CHUNK_SIZE,
            );

            const piecesNum = staticChunks.length + dynamicChunks.length;

            staticChunks.forEach((entities, pieceId) => {
                this.queue.push(() => this.tracking?.track(trackEventTitle, {
                    ...allProperties,
                    designCreationType: DESIGN_CREATION_TYPE.STATIC,
                    entities,
                    pieceId,
                    piecesNum,
                    dynamicPiecesNum: dynamicChunks.length,
                }, {}));
            });

            dynamicChunks.forEach((entities, pieceId) => {
                this.queue.push(() => this.tracking?.track(trackEventTitle, {
                    ...allProperties,
                    designCreationType: DESIGN_CREATION_TYPE.DYNAMIC,
                    entities,
                    pieceId: pieceId + staticChunks.length,
                    piecesNum,
                    dynamicPiecesNum: dynamicChunks.length,
                }, {}));
            });
        }
    }

    public trackExperiment(properties: Gallery.Analytics.GalleryExperimentImpressions): void {
        if (this.queue) {
            this.queue.push(() => fireImpression(
                properties.experimentId,
                properties.experimentVariationId,
            ));
        }
    }

    public trackAttributeSelected(optionName: string, optionValue: string | undefined): void {
        if (this.queue) {
            this.queue.push(() => trackAttributeSelected({
                category: this.trackingLabel,
                label: optionName,
                eventDetail: optionValue,
                ...this.getPageProperties(),
            }));
        }
    }

    public trackProductOptionsSelected(properties: Gallery.Analytics.ProductOptionsSelected): void {
        const {
            startingProductOptions,
            currentEntity,
            initialEstimatedPriceData,
            currentEstimatedPriceData,
            startingQuantity,
            currentQuantity,
        } = properties;

        const productUpdate = this.determineUpsellOrDownsell(
            initialEstimatedPriceData?.estimatedPrices?.[`${currentQuantity}`]?.totalDiscountedPrice.untaxed ?? 0,
            currentEstimatedPriceData?.estimatedPrices?.[`${currentQuantity}`]?.totalDiscountedPrice.untaxed ?? 0,
        );

        const initialBreakdown = initialEstimatedPriceData?.estimatedPrices[`${startingQuantity}`]?.breakdown ?? [];
        const currentBreakdown = currentEstimatedPriceData?.estimatedPrices[`${currentQuantity}`]?.breakdown ?? [];

        const productOptions = Object.entries(currentEntity.productOptions || {}).map(
            ([optionName, optionValue]: [string, string]) => {
                const initialOptionPrice = this.getBreakdownOptionPrice(initialBreakdown, optionName);
                const currentOptionPrice = this.getBreakdownOptionPrice(currentBreakdown, optionName);
                const optionProductUpdate = this.determineUpsellOrDownsell(initialOptionPrice, currentOptionPrice);

                return {
                    key: optionName,
                    value: optionValue,
                    isUpdated: optionValue !== startingProductOptions[optionName],
                    isVisible: true,
                    update: optionProductUpdate,
                };
            },
        );

        if (this.state.config.quantity) {
            productOptions.push({
                key: 'Quantity',
                value: `${currentQuantity ?? 0}`,
                isUpdated: currentQuantity !== startingQuantity,
                isVisible: true,
                update: this.getProductUpdateForQuantity(startingQuantity, currentQuantity),
            });
        }

        const variant = Object.entries(currentEntity.productOptions).map(
            ([optionName, optionValue]: [string, string]) => `${optionName}:${optionValue}`,
        ).join('_');

        if (this.queue) {
            this.queue.push(() => trackProductOptionsSelected({
                category: 'Gallery',
                label: this.trackingLabel,
                product_id: this.mpvId,
                name: this.h1Title || '',
                core_product_id: this.productKey,
                productOptions,
                productUpdate,
                price: currentEstimatedPriceData?.estimatedPrices?.[`${currentQuantity}`]?.totalDiscountedPrice.untaxed ?? 0,
                list_price: currentEstimatedPriceData?.estimatedPrices?.[`${currentQuantity}`]?.totalListPrice.untaxed ?? 0,
                sales_quantity: currentQuantity ?? 0,
                variant,
                ...this.getPageProperties(),
            }));
        }
    }

    public getProductUpdateForQuantity(
        initialQuantity?: number,
        finalQuantity?: number,
    ): PRODUCT_OPTIONS_PRODUCT_UPDATE {
        if (!finalQuantity || !initialQuantity) {
            return PRODUCT_OPTIONS_PRODUCT_UPDATE.NO_CHANGE;
        }
        if (finalQuantity > initialQuantity) {
            return PRODUCT_OPTIONS_PRODUCT_UPDATE.UPSELL;
        } if (finalQuantity < initialQuantity) {
            return PRODUCT_OPTIONS_PRODUCT_UPDATE.DOWNSELL;
        }
        return PRODUCT_OPTIONS_PRODUCT_UPDATE.NO_CHANGE;
    }

    public getBreakdownOptionPrice(
        breakdown: VP.PCT.Models.ProductCatalogPricingService.EstimatedPriceBreakdown[],
        optionName: string,
    ): number {
        return breakdown.find((breakdownOption) => breakdownOption.name === optionName)?.listPrice.untaxed ?? 0;
    }

    public determineUpsellOrDownsell(initialPrice: number, currentPrice: number): PRODUCT_OPTIONS_PRODUCT_UPDATE {
        let productUpdate = PRODUCT_OPTIONS_PRODUCT_UPDATE.NO_CHANGE;

        if (currentPrice > initialPrice) {
            productUpdate = PRODUCT_OPTIONS_PRODUCT_UPDATE.UPSELL;
        } else if (currentPrice < initialPrice) {
            productUpdate = PRODUCT_OPTIONS_PRODUCT_UPDATE.DOWNSELL;
        }
        return productUpdate;
    }

    public buildDesignEngagement(params: {
        engagementAction: string,
        selectedDesign: string,
        color: string | undefined,
        // eslint-disable-next-line default-param-last
        tileEntity: State.TileEntity | Gallery.QuickView.QuickViewCurrentDesignData,
        colorSwatchObjects: Gallery.ContentQuery.ColorSwatch[],
        impressionId: string | ((id: string) => string),
        location?: string,
        position?: number,
        selectedQuickViewOptions?: Gallery.ContentQuery.ProductOptions,
        didQuickViewOptionsChange?: boolean,
        engagementId?: string,
    }): Gallery.Analytics.GalleryDesignEngagement {
        // position is undefined if we're sending the data from quickview
        // tileEntity and location are undefinable to make initializing the ColorSwatchAnalyticsContext easier
        const {
            engagementAction,
            selectedDesign,
            color,
            tileEntity = {} as State.TileEntity,
            colorSwatchObjects,
            impressionId,
            location,
            position,
            selectedQuickViewOptions,
            didQuickViewOptionsChange,
            engagementId,
        } = params;
        const {
            productOptions,
            productKey,
            product,
            colorSwatches,
            designId,
            legacyComboId,
            isForceRanked,
            designCreationType,
        } = params.tileEntity;
        const { currentEntityId } = tileEntity as Gallery.QuickView.QuickViewCurrentDesignData;
        const normalizedColorSwatches = colorSwatches.map((cs) => (typeof cs === 'string' ? cs : cs.designId));
        const colorSwatchValues = colorSwatchObjects.map((cs) => cs.color?.split('#')[1]).filter(Boolean);

        return {
            color,
            colorSwatchValues,
            isForceRanked,
            position,
            productKey,
            product,
            location,
            // This field is used by FSD DNA
            engagementAction,
            id: designId,
            designCreationType,
            colorSwatches: normalizedColorSwatches,
            galleryEngagementId: engagementId,
            galleryImpressionId: typeof impressionId === 'string' ? impressionId : impressionId(designId),
            refinements: getSelectedFilters(this.state),
            route: config.client.segmentRoute,
            selectedQuickViewOptions: selectedQuickViewOptions
                && this.convertToAnalyticsProductOptions(selectedQuickViewOptions),
            selectedDesign: color ? selectedDesign : legacyComboId, // Pull comboId off tile if no color swatches
            selectedDesignId: currentEntityId || designId,
            productOptions: this.convertToAnalyticsProductOptions(productOptions),
            didQuickViewOptionsChange,
            naturalRankingStrategy: getNaturalRankingStrategySelector(this.state),
            forcedRankingStrategy: getForcedRankingStrategySelector(this.state),
        };
    }

    public buildUpsellEngagement(params: {
        selectedDesign: string,
        selectedDesignId: string,
        productKey: string,
        productOptions: Gallery.ContentQuery.ProductOptions,
        optionName: string,
        oldOptionValue: string,
        newOptionValue: string,
        designCreationType: Gallery.ContentQuery.DESIGN_CREATION_TYPE,
        color?: string,
    }): Gallery.Analytics.GalleryUpsellEngagement {
        const {
            selectedDesign,
            selectedDesignId,
            productKey,
            productOptions,
            optionName,
            oldOptionValue,
            newOptionValue,
            designCreationType,
            color,
        } = params;

        return {
            selectedDesign,
            selectedDesignId,
            productKey,
            productOptions: this.convertToAnalyticsProductOptions(productOptions),
            optionName,
            oldOptionValue,
            newOptionValue,
            designCreationType,
            route: config.client.segmentRoute,
            color,
        };
    }

    public buildFullBleedUploadMetaData(): Gallery.Analytics.FullBleedUploadAnalyticsMetadata {
        return {
            filterApplied: getSelectedFilters(this.state),
            route: config.client.segmentRoute,
        };
    }

    public trackEvent<T extends any>(
        options: Gallery.Analytics.TrackEventOptions<T>,
    ): void {
        const {
            action,
            category = ANALYTICS_CATEGORY.GALLERY,
            eventLabel,
            eventDetail = '',
            ...rest
        } = options;
        const properties = {
            ...rest,
            category,
            eventDetail,
            label: eventLabel,
            product_id: this.mpvId,
            galleryName: this.galleryName as string,
            galleryId: this.galleryId as string,
            galleryTitle: this.h1Title as string,
            internalGalleryId: this.internalGalleryId as number,
            core_product_id: this.productKey,
            core_product_version: this.productVersion,
            mpvId: this.mpvId as string,
            experienceType: this.experienceType,
            ...this.getPageProperties(),
        } as Gallery.Analytics.UserEvent<T>;

        if (this.queue) {
            this.queue.push(() => this.tracking?.track(action, properties, {}));
        }
    }

    public convertToAnalyticsProductOptions(
        productOptions: Gallery.ContentQuery.ProductOptions,
    ): Gallery.Analytics.ProductOption[] {
        return Object.entries(productOptions).map(([optionName, optionValue]) => ({
            optionName,
            optionValue,
        }));
    }
}
