import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
    Accordion, BasicCollapsible, Link, List, ListItem, Typography,
} from '@vp/swan';

import { getQuicklinks } from '~/client/store/config/reducer';

export const SidebarQuickLinks = memo((): JSX.Element => {
    const quickLinks = useSelector(getQuicklinks);

    const validQuickLinks = useMemo(
        () => quickLinks?.filter((ql) => !!ql.links.length) ?? [],
        [quickLinks],
    );
    const filterExpandedMap = useMemo(
        () => Object.fromEntries(quickLinks?.map((ql) => ([ql.category, true])) ?? []),
        [quickLinks],
    );

    return (
        <Accordion className="filter-container" defaultExpandedCollapsibles={filterExpandedMap}>
            {validQuickLinks.map((ql) => (
                <BasicCollapsible
                    defaultExpanded
                    className="filter"
                    collapsibleId={ql.category}
                    heading={ql.category}
                    role="button"
                >
                    <List skin="minimal">
                        {ql.links.map((link) => (
                            <ListItem>
                                <Typography fontSize="small">
                                    <Link href={link.href} skin="unstyled">
                                        {link.name}
                                    </Link>
                                </Typography>
                            </ListItem>
                        ))}
                    </List>
                </BasicCollapsible>
            ))}
        </Accordion>
    );
});

SidebarQuickLinks.displayName = 'QuickLinks';
