import { MouseEvent } from 'react';
import { FlexBox, Link } from '@vp/swan';
import { RatingSummary } from '@vp/rating-reviews-component/build/pure/index';
import { RatingReviewData } from '@vp/rating-reviews-component';

import { reviewsId } from 'client/components/Gallery/Footer/ReviewsCallout';
import { scrollToElement } from 'client/utils/scrollToElement';

export interface PropTypes {
    ratingsAndReviews: RatingReviewData;
}

export const RatingsSummary = ({ ratingsAndReviews }: PropTypes): JSX.Element | null => {
    const handleScrollToReviews = (event: MouseEvent<HTMLAnchorElement>): void => {
        event.preventDefault();

        scrollToElement(reviewsId, { behavior: 'smooth' });
    };

    return (
        <FlexBox alignItems="center" fontSize="small">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link className="rating-link" onClick={handleScrollToReviews}>
                <RatingSummary
                    {...ratingsAndReviews.summary}
                />
            </Link>
        </FlexBox>
    );
};

RatingsSummary.displayName = 'RatingsSummary';
