import { useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import deepequals from 'fast-deep-equal/es6/index';
import {
    Box,
    Combobox, ComboboxList, ComboboxOption, ComboboxPopover,
    ComboboxSearchInput,
    Spinner,
} from '@vp/swan';
import { useFilterOptions } from 'client/hooks/useFilterOptions';
import { selectFilters } from 'client/store/filter';
import { REFINEMENT_DIMENSION } from 'shared/constants';
import { scrollUpToElement } from 'client/utils/scrollToElement';
import { galleryHeaderId } from 'client/components/Gallery/Header/constants';
import { useTranslations } from 'client/hooks/useTranslations';
import { Highlight } from 'client/components/common/Highlight';
import { useSuggestions } from 'client/components/Gallery/Header/Searchbox/hooks/useSuggestions';
import {
    buildRefinement,
    refinementAddAndRemove,
} from 'client/store/refinement';
import { useRecoilValue } from 'recoil';
import { searchAutoFocusedState } from 'client/atoms/filtersFlyout';
import { useRedirectToL0 } from '~/client/hooks/RedirectToL0/useRedirectToL0';

export const SearchBox = (): JSX.Element => {
    const dispatch = useDispatch();
    const localize = useTranslations();
    const wrapperRef = useRef(null);
    const portalContainer = wrapperRef.current ?? undefined;
    const redirectToL0 = useRedirectToL0();

    // Recoil state is needed to allow the mobile search button to open the filters flyout and focus the search input.
    const searchAutoFocused = useRecoilValue(searchAutoFocusedState);

    const colorFilter = useSelector(selectFilters, deepequals).find((f) => f.type.toUpperCase() === 'COLOR');
    const { options: colorOptions } = useFilterOptions(colorFilter as State.AttributeFilter);
    const colorDict = useMemo(
        () => colorOptions.reduce((accum, option) => ({
            ...accum,
            [option.title.toLocaleUpperCase()]: option.value,
        }), {} as Record<string, string>),
        [colorOptions],
    );

    const list = useSuggestions();

    const shouldShowLoading = list.items.length === 0 && list.isLoading;

    const handleInputChange = (filterText: string): void => {
        list.setFilterText(filterText);
    };

    const handleSearch = (search: string, isAutosuggest: boolean): void => {
        const searchTerms = search.split(' ');

        if (searchTerms.length === 1 && colorDict[search.toLocaleUpperCase()]) {
            const refinement = buildRefinement(
                colorDict[search.toLocaleUpperCase()],
                REFINEMENT_DIMENSION.ATTRIBUTE,
            );

            if (redirectToL0([refinement], [])) {
                return;
            }

            dispatch(refinementAddAndRemove([refinement]));
        } else {
            const isBlackFriday = search.toLocaleUpperCase().includes('BLACK FRIDAY');

            const refinementsToAdd = !isBlackFriday ? searchTerms.reduce((accum, term) => {
                if (colorDict[term.toLocaleUpperCase()]) {
                    const refinement = buildRefinement(
                        colorDict[term.toLocaleUpperCase()],
                        REFINEMENT_DIMENSION.ATTRIBUTE,
                    );

                    accum.push(refinement);
                }

                return accum;
            }, [] as State.Refinement[]) : [];

            refinementsToAdd.push(buildRefinement(search, REFINEMENT_DIMENSION.KEYWORD, { isAutosuggest }));

            if (redirectToL0(refinementsToAdd, [])) {
                return;
            }

            dispatch(refinementAddAndRemove(refinementsToAdd));
        }

        scrollUpToElement(galleryHeaderId);
        list.setFilterText('');
    };

    const handleSelect = (value: string | number): void => {
        list.setFilterText('');

        if (value) {
            handleSearch(`${value}`, true);
        }
    };

    const handleSubmit = (event: React.SyntheticEvent): void => {
        event.preventDefault();

        if (list.filterText) {
            handleSearch(list.filterText, false);
        }
    };

    return (
        <div className="search-box" ref={wrapperRef}>
            <form role="search" onSubmit={handleSubmit}>
                <Combobox
                    allowsCustomValue
                    disableAutoFilter
                    allowEmptyCollection={list.isLoading}
                    aria-label={localize('HeaderHierarchySearchDesigns')}
                    inputValue={list.filterText}
                    items={list.items}
                    onInputChange={handleInputChange}
                    onSelectionChange={handleSelect}
                >
                    <ComboboxSearchInput
                        accessibleTextForClearButton={localize('SearchBoxAccessibleTextForClearButton')}
                        accessibleTextForSearchButton={localize('SearchBoxAccessibleTextForSearchButton')}
                        autoFocus={searchAutoFocused}
                        placeholder={localize('HeaderHierarchySearchDesigns')}
                        title={localize('SearchBoxTextInputTitle')}
                    />
                    <ComboboxPopover portalContainer={portalContainer}>
                        <ComboboxList>
                            {({ val: value }): JSX.Element => (
                                <ComboboxOption key={value} textValue={value}>
                                    <Highlight fullText={value} searchTerm={list.filterText} />
                                </ComboboxOption>
                            )}
                        </ComboboxList>
                        {shouldShowLoading && (
                            <Box pb="5" pt={0} px="4">
                                <Spinner
                                    showText
                                    accessibleText={localize('PhotoPersonalizationImageSelectionAssetLoadingLabel')}
                                    layout="horizontal"
                                    size="mini"
                                />
                            </Box>
                        )}
                    </ComboboxPopover>
                </Combobox>
            </form>
        </div>
    );
};

SearchBox.displayName = 'SearchBox';
