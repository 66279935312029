import { RefObject, useEffect, useState } from 'react';
import { ONE_COLOR_SWATCH_WIDTH } from '~/client/components/Gallery/NewDesignTile/constants';
import { ColorSwatchesParams } from '~/client/components/Gallery/NewDesignTile/interface';

export const useColorSwatches = (
    colorSwatchesLength: number,
    ref: RefObject<HTMLDivElement>,
    handleColorSwatchChange: (selectedValue: string) => void,
): ColorSwatchesParams => {
    const [shouldShowShowMoreButton, setShouldShowShowMoreButton] = useState(false);

    const shouldShowColorSwatches = colorSwatchesLength > 1;
    const onColorSwatchChangeWrapper = (selectedValue: string | number | null): void => {
        const stringifiedValue = selectedValue?.toString() || '';

        handleColorSwatchChange(stringifiedValue);
    };

    useEffect(() => {
        const handleCheckShouldShowButton = (): void => {
            if (ref.current) {
                const containerWidth = ref.current.offsetWidth;
                const maxElementsPerRow = Math.floor(containerWidth / ONE_COLOR_SWATCH_WIDTH);

                setShouldShowShowMoreButton(colorSwatchesLength > maxElementsPerRow);
            }
        };

        const resizeObserver = new ResizeObserver(handleCheckShouldShowButton);

        if (ref.current) {
            resizeObserver.observe(ref.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [ref, colorSwatchesLength]);

    return {
        shouldShowColorSwatches,
        shouldShowShowMoreButton,
        onColorSwatchChangeWrapper,
    };
};
