import { useSelector } from 'react-redux';
import { selectQueryState, selectQueryString } from '~/client/store/locationState';
import { booleanRenderPropertySelector, getBreadcrumbs } from '~/client/store/config/reducer';
import { mutateQueryStateParamsWithRefinementToAdd, mutateQueryStateParamsWithRefinementToRemove } from '~/client/hooks/RedirectToL0/utils';
import { RenderProperty } from '~/shared/renderProperties';

type UseRedirectToL0ReturnValue = (
    refinementToAdd: State.Refinement[],
    refinementToRemove: State.Refinement[],
) => boolean;

export const useRedirectToL0 = (): UseRedirectToL0ReturnValue => {
    const breadcrumbs = useSelector(getBreadcrumbs);
    const queryState = useSelector(selectQueryState);
    const booleanRenderProperty = useSelector(booleanRenderPropertySelector);

    const isRedirectToL0Enabled = booleanRenderProperty(RenderProperty.RedirectToL0);

    const tlpL0Url = breadcrumbs && breadcrumbs[3]?.url;

    return (
        refinementToAdd: State.Refinement[],
        refinementToRemove: State.Refinement[],
    ): boolean => {
        if (!isRedirectToL0Enabled) {
            return false;
        }

        const newQueryStateParams = { ...queryState.parameters };

        mutateQueryStateParamsWithRefinementToAdd(newQueryStateParams, refinementToAdd);
        mutateQueryStateParamsWithRefinementToRemove(newQueryStateParams, refinementToRemove);

        const queryString = selectQueryString({
            parameters: newQueryStateParams,
            replace: false,
        }, '');

        if (tlpL0Url) {
            window.location.href = tlpL0Url + queryString;
            return true;
        }

        return false;
    };
};
