import { isExperimentActive, trackExperimentImpression } from 'shared/ab-testing';
import { useAnalytics } from 'client/hooks/gallery/useAnalytics';
import { getRawExperiments } from 'client/store/experimentation';
import { useSelector } from 'react-redux';
import { getQuickViewId } from 'client/store/debug';

const cache: Record<string, boolean> = {};

export enum TRACKING_BEHAVIOR {
    Default,
    Force,
    Suppress,
}

export type IsExperimentActive = (
    featureFlagId: string,
    trackingBehavior?: TRACKING_BEHAVIOR
) => boolean;

export const useExperimentation = (): IsExperimentActive => {
    const rawExperiments = useSelector(getRawExperiments);
    const analytics = useAnalytics();
    const isDirectQuickView = useSelector(getQuickViewId);

    return (
        experimentFeatureFlag: string,
        trackingBehavior: TRACKING_BEHAVIOR = TRACKING_BEHAVIOR.Default,
    ): boolean => {
        const active = isExperimentActive(experimentFeatureFlag, rawExperiments);

        if (trackingBehavior !== TRACKING_BEHAVIOR.Suppress
            && (!isDirectQuickView || trackingBehavior === TRACKING_BEHAVIOR.Force)
            && !cache[experimentFeatureFlag]
            && active
            && typeof window !== 'undefined'
        ) {
            cache[experimentFeatureFlag] = true;
            trackExperimentImpression(experimentFeatureFlag, rawExperiments, analytics);
        }

        return active;
    };
};
