/**
 * Determines if the browser is running on an iOS device based on the user agent string
 * @returns boolean
 */
export const isIos = (): boolean => (typeof navigator !== 'undefined' ? Boolean(navigator.userAgent.match(/iPhone|iPad|iPod/i)) : false);

/**
 * Determines if the browser is running on an Android device based on the user agent string
 * @returns boolean
 */
export const isAndroid = (): boolean => (typeof navigator !== 'undefined' ? Boolean(navigator.userAgent.match(/android/i)) : false);

/**
 * Determines if the user's primary input method includes a pointing device with limited accuracy (i.e., is touch-based)
 * @returns boolean
 */
export const isCoarsePointer = (): boolean => (typeof window !== 'undefined' ? window.matchMedia('(pointer:coarse)').matches : false);

/**
 * Determines is the user is on a mobile device (iOS, Android, or touch screen)
 * @return boolean
 */
export const isMobile = (): boolean => (isIos() || isAndroid() || isCoarsePointer());
