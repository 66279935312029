import { useGalleryParams } from 'client/hooks/useGalleryParams';
import { lazy, Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { galleryHeaderId } from '~/client/components/Gallery/Header/constants';
import { tileEntityByIdSelector } from '~/client/store/tileEntity';
import { scrollUpToElement } from '~/client/utils/scrollToElement';

const RouteableQuickView = lazy(() => import('client/components/Gallery/PreviewArea/RouteableQuickView'));

export const QuickViewOutlet = (): JSX.Element | null => {
    const tileEntityById = useSelector(tileEntityByIdSelector);
    const { designId, segment } = useGalleryParams();
    const location = useLocation();
    const navigate = useNavigate();

    const tileEntity = designId && tileEntityById(designId);

    useEffect(() => {
        if (designId && !tileEntity && segment?.length) {
            navigate({
                ...location,
                pathname: segment.join('/'),
            }, {
                replace: true,
            });

            scrollUpToElement(galleryHeaderId);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [designId, tileEntity]);

    return (
        <Suspense fallback={null}>
            {tileEntity && <RouteableQuickView designId={designId} />}
        </Suspense>
    );
};
