import { createSelector } from 'reselect';

import { getLatsLoadMoreLength } from 'client/store/config/reducer';
import {
    currentPageSelector, currentPageSizeSelector, getCurrentOffsetModifier, loadMoreCountSelector,
} from 'client/store/paging/selectors';
import { MINIMUM_TEMPLATES_SIZE } from 'client/store/paging/constants';

export const getIsLoadMoreAvailable = createSelector([
    loadMoreCountSelector,
    currentPageSizeSelector,
    getLatsLoadMoreLength,
    getCurrentOffsetModifier,
    currentPageSelector,
], (
    loadMoreCount,
    currentPageSize,
    latsLoadMoreLength,
    offsetModifier,
    currentPage,
) => {
    const isFirstPage = currentPage === 1;

    const isMoreTemplatesAvailableInAPI = latsLoadMoreLength
        >= (MINIMUM_TEMPLATES_SIZE - (isFirstPage ? offsetModifier : 0));
    const isMoreTemplatesFitsInPageLimit = loadMoreCount + 1 < currentPageSize / MINIMUM_TEMPLATES_SIZE;

    return isMoreTemplatesAvailableInAPI && isMoreTemplatesFitsInPageLimit;
});
