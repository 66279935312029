import { AnyAction } from 'redux';
import { buildDesignPersonalizationContext, customImagesToDpcImages, personalizedTextToDpcText } from 'client/store/personalization/util';

const INITIAL_STATE: State.PersonalizationState = {
    logoApplied: false,
    photoPreviews: [],
    personalizedTextData: {},
    designPersonalizationContext: undefined,
};

export const LOGO_APPLIED_UPDATE = 'LOGO_APPLIED_UPDATE';
export const PERSONALIZED_TEXT_UPDATE = 'PERSONALIZED_TEXT_UPDATE';
export const CUSTOM_IMAGES_UPDATE = 'CUSTOM_IMAGES_UPDATE';

export function reducer(
    state: State.PersonalizationState = INITIAL_STATE,
    action: AnyAction,
): State.PersonalizationState {
    switch (action.type) {
        case LOGO_APPLIED_UPDATE:
            return {
                ...state,
                logoApplied: action.payload.logoApplied,
            };
        case PERSONALIZED_TEXT_UPDATE: {
            const newDpcText = personalizedTextToDpcText(action.payload.text);
            const updatedContext = buildDesignPersonalizationContext({
                text: newDpcText,
                images: state.designPersonalizationContext?.images,
            });

            return {
                ...state,
                personalizedTextData: action.payload.text,
                designPersonalizationContext: updatedContext,
            };
        }

        case CUSTOM_IMAGES_UPDATE: {
            const newDpcImages = customImagesToDpcImages(action.payload.images);
            const updatedContext = buildDesignPersonalizationContext({
                text: state.designPersonalizationContext?.text,
                images: newDpcImages,
            });

            return {
                ...state,
                photoPreviews: action.payload.images,
                designPersonalizationContext: updatedContext,
            };
        }
        default:
            return state;
    }
}
