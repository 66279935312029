import classnames from 'classnames';
import {
    HTMLProps, memo, useEffect, useState,
} from 'react';
import { FilterMenu } from 'client/components/Gallery/Header/FilterMenu';
import { SearchBox } from 'client/components/Gallery/Header/Searchbox';
import { FavoritesCTA } from 'client/components/Gallery/Header/Favorites';
import { useAuth } from 'client/hooks/useAuth';
import { useFavorites } from 'client/hooks/features/useFavorites';
import { SearchButton } from 'client/components/Gallery/Header/SearchButton';
import { useExperimentation } from '~/client/hooks/useExperimentation';
import { PERSONALIZATION_UX_MOBILE_VARIATIONS } from '~/experiments/tilePersonalization/constants';
import { isMobile } from '~/client/utils/deviceDetection';
import { MobilePersonalization } from '~/client/components/Gallery/Header/Personalization/MobilePersonalization';

export const StickyHeader = memo((props: HTMLProps<HTMLDivElement>): JSX.Element => {
    const { className, ...rest } = props;
    const showFavorites = useFavorites();
    const auth = useAuth();
    const isExperimentActive = useExperimentation();
    const mobilePersonalizationExperimentEnabled = isExperimentActive(PERSONALIZATION_UX_MOBILE_VARIATIONS.Enabled);
    const [isMobilePersonalizationEnabled, setMobilePersonalizationEnabled] = useState(false);

    useEffect(() => {
        setMobilePersonalizationEnabled(isMobile() && mobilePersonalizationExperimentEnabled);

        if (!mobilePersonalizationExperimentEnabled && isMobile()) {
            isExperimentActive(PERSONALIZATION_UX_MOBILE_VARIATIONS.Control);
        }
    }, [isExperimentActive, mobilePersonalizationExperimentEnabled]);

    const shouldShowFavorites = showFavorites && auth;

    return (
        <div
            className={classnames('sticky-header', 'mobile-sticky-header-visible', className)}
            {...rest}
        >
            <div className="mobile-sticky-header-bounded-content">
                <div className="mobile-sticky-header-actions">
                    <div className="subheader-actions">
                        <SearchBox />
                        <SearchButton />
                        {shouldShowFavorites && (<FavoritesCTA className="mobile-rounded-icon-button" userInteractionLocation="staticHeader" />)}
                        {isMobilePersonalizationEnabled && <MobilePersonalization />}
                        <FilterMenu />
                    </div>
                </div>
            </div>
        </div>
    );
});

StickyHeader.displayName = 'StickyHeader';
