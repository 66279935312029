// import { useInView } from 'react-intersection-observer';
import { Box } from '@vp/swan';

import { ReviewDisplay } from '@vp/rating-reviews-component/build/pure/index';
import { useRatingsAndReviews } from '~/client/hooks/useRatingsAndReviews';

export const reviewsId = 'gallery-reviews';

export const ReviewsCallout = (): JSX.Element | null => {
    // FIXME when we figure out refs in swan
    // const { ref: reviewsRef } = useInView();

    const ratingsAndReviews = useRatingsAndReviews();

    return ratingsAndReviews ? (
        <Box
            className="reviews-container"
            id={reviewsId}
            paddingX="4"
            // ref={reviewsRef}
            // SWAN TODO
        >
            <ReviewDisplay {...ratingsAndReviews} />
        </Box>
    ) : null;
};
