import { Link, LinkProps } from '@vp/swan';
import { useAuth } from 'client/hooks/useAuth';
import { useTranslations } from 'client/hooks/useTranslations';

type SignInButtonProps = Omit<LinkProps, 'ref'> & {
    beforeSignIn?: () => void;
    isPersonalizationUI?: boolean;
}

export const SignInButton = (props: SignInButtonProps): JSX.Element | null => {
    const auth = useAuth();
    const { beforeSignIn, isPersonalizationUI, ...rest } = props;
    const localize = useTranslations();

    const handleOnClick = (): void => {
        beforeSignIn?.();
        auth?.signIn();
    };

    return (
        <Link
            fontSize="small"
            {...(isPersonalizationUI && { fontWeight: 'bold' })}
            onClick={handleOnClick}
            {...rest}
        >
            {isPersonalizationUI ? localize('LogInButtonText') : localize('SignInButtonText')}
        </Link>
    );
};
