import { memo } from 'react';
import { useSelector } from 'react-redux';
import { useRecoilState } from 'recoil';
import classNames from 'classnames';
import { PREVIEW_TYPE } from 'shared/constants';
import { ComparativeNameDisplay } from '~/client/components/common/ComparativeNameDisplay';
import { DesignTile } from '~/client/components/common/DesignTile';
import { RenderProperty } from 'shared/renderProperties';
import {
    getLocaleSelector, getMarket, getQuantitySelector, stringRenderPropertySelector,
} from '~/client/store/config/reducer';
import { ContextualBatchPricingDisplay } from '~/client/components/common/PricingDisplay/ContextualBatchPricingDisplay';
import { productOptionsByProductKeySelector } from '~/client/store/productOptionsByProductKey/reducer';
import { ColorSwatchPicker } from '~/client/components/common/ColorSwatchPicker';
import { useGalleryParams } from '~/client/hooks/useGalleryParams';
import { tileEntityByIdSelector } from '~/client/store/tileEntity';
import { IMAGE_STYLE_PROPS } from 'src/experiments/MerchModules/constants';
import { useFavorites } from '~/client/hooks/features/useFavorites';
import { TileEntityFavorite } from '~/client/components/Gallery/PreviewArea/TileEntityFavorite';
import { merchModulesCurrentFavoritesState } from '~/client/atoms/currentFavoritesAtom';
import { useQueryFavorites } from '~/client/hooks/useFavorites';
import { WES_TENANT } from '~/client/constants';
import { useAuth } from '~/client/hooks/useAuth';
import { mapFavoritesWorkIdsToEntityIds } from '~/client/utils/mapFavoritesWorkIdsToEntityIds';
import { merchModulesTileEntityAllIdsSelector } from '~/client/store/merchModulesTileEntities';
import { MerchModulesTileEntityProvider } from '~/experiments/MerchModules/MerchModulesTileEntityProvider';
import { MerchModuleTileProps } from '~/experiments/MerchModules/interface';
import { isMerchModulesEnabled } from '~/experiments/MerchModules/utils';
import { getRawExperiments } from '~/client/store/experimentation';

const MerchModuleTileComponent = ({ entityId }: MerchModuleTileProps): JSX.Element => {
    const tileEntityById = useSelector(tileEntityByIdSelector);
    const stringRenderProperty = useSelector(stringRenderPropertySelector);
    const tileEntity = tileEntityById(entityId);
    const tileEntityIds = useSelector(merchModulesTileEntityAllIdsSelector);
    const productOptionsByProductKey = useSelector(productOptionsByProductKeySelector);
    const locale = useSelector(getLocaleSelector);
    const market = useSelector(getMarket);
    const auth = useAuth();
    const quantity = useSelector(getQuantitySelector);
    const [currentFavorites, setCurrentFavorites] = useRecoilState(merchModulesCurrentFavoritesState);
    const showFavorites = useFavorites();
    const rawExperiments = useSelector(getRawExperiments);

    const { segment } = useGalleryParams();

    const shouldShowMerchModules = isMerchModulesEnabled(rawExperiments);

    const pricingPresentationType = stringRenderProperty(RenderProperty.PricingPresentationType);

    useQueryFavorites(
        locale as i18n.Locale,
        WES_TENANT,
        auth?.canonicalId,
        auth?.accessToken,
        tileEntity.productKey,
        {
            enabled: showFavorites && !!auth && !!auth.canonicalId && !!auth.accessToken && !!locale,
            retry: 1,
            staleTime: Infinity,
            onSuccess(data) {
                setCurrentFavorites(mapFavoritesWorkIdsToEntityIds(tileEntityIds, data));
            },
        },
    );

    return (
        <MerchModulesTileEntityProvider key={entityId} tileEntityId={entityId}>
            {(
                merchTileEntity,
                imageProps,
                handleColorSwatchChange,
                colorSwatchObjects,
                currentColorSwatch,
                currentDesignId,
            ): JSX.Element | null => (
                <div className={classNames(
                    'merch-modules-tile-wrapper',
                    { 'empty-color-swatch': !currentColorSwatch.color },
                )}
                >
                    <DesignTile
                        className="design-tile-list"
                        imageProps={{
                            ...imageProps,
                            loading: 'lazy',
                        }}
                        imageStyleProps={IMAGE_STYLE_PROPS}
                        linkProps={segment ? {
                            noFollow: true,
                            state: { designId: currentDesignId },
                            to: `${segment?.join('/')}/qv/${merchTileEntity.designId}`,
                            className: 'tile-link',
                        } : undefined}
                    >
                        {showFavorites && (
                            <TileEntityFavorite
                                colorSwatchObjects={colorSwatchObjects}
                                entityId={entityId}
                                favoriteId={currentFavorites.get(merchTileEntity.designId)}
                                previewType={PREVIEW_TYPE.TILE}
                            />
                        )}
                        <div className="tile-info">
                            <ComparativeNameDisplay className="title-name">
                                {merchTileEntity.comparativeName}
                            </ComparativeNameDisplay>
                            <ContextualBatchPricingDisplay
                                fullProductOptions={merchTileEntity.fullProductOptions}
                                locale={locale as i18n.Locale}
                                market={market}
                                pricingPresentationType={pricingPresentationType}
                                productKey={merchTileEntity.productKey}
                                productOptionsByProductKey={productOptionsByProductKey}
                                productOptionsHash={merchTileEntity.productOptionsHash}
                                productVersion={merchTileEntity.productVersion}
                                quantity={quantity}
                            />
                        </div>
                        <ColorSwatchPicker
                            collapse
                            colorSwatches={currentColorSwatch.colorSwatches}
                            entityId={entityId}
                            locale={locale as i18n.Locale}
                            selectedDesignId={currentColorSwatch.designId}
                            shouldShowMerchModules={shouldShowMerchModules}
                            onColorSwatchChange={handleColorSwatchChange}
                        />
                    </DesignTile>
                </div>
            )}
        </MerchModulesTileEntityProvider>

    );
};

export const MerchModuleTile = memo(MerchModuleTileComponent);
