import { forwardRef, memo } from 'react';
import { Box, FluidImage, Spinner } from '@vp/swan';
import { TemplateTileImageProps } from '~/client/components/common/TemplateTile/components/TemplateTileImage/interface';
import classnames from 'classnames';

export const TemplateTileImage = memo(forwardRef<HTMLImageElement, TemplateTileImageProps>((
    {
        alt,
        loading,
        src,
        srcSet,
        renderWrapper: ComponentWrapper,
        onError,
        onLoad,
        showSpinner,
        accessibilityText,
        isLargePreview,
    },
    ref,
): JSX.Element => {
    const imagePreview = (
        <>
            {showSpinner && (
            <div className="template-tile-spinner">
                <Spinner accessibleText={accessibilityText} size="standard" />
            </div>
            )}
            <FluidImage
                alt={alt}
                className={classnames('template-tile-image', { 'template-tile-image-large': isLargePreview })}
                loading={loading}
                ref={ref}
                src={src}
                srcSet={srcSet}
                onError={onError}
                onLoad={onLoad}
            />
        </>
    );

    if (ComponentWrapper) {
        return (
            <ComponentWrapper>
                <Box className="template-image-wrapper">{imagePreview}</Box>
            </ComponentWrapper>
        );
    }

    return (
        imagePreview
    );
}));
