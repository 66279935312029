import { PURPOSE_NAMES } from './constants';

export type TemplateField = {
    purposeName: PURPOSE_NAMES;
    placeholder: string;
}

export function isPurposeName(value: string): value is PURPOSE_NAMES {
    return Object.values<string>(PURPOSE_NAMES).includes(value);
}

export function parsePurposeFields(textFieldsString: string, localize: i18n.Localize): TemplateField[] {
    const purposeNames: PURPOSE_NAMES[] = textFieldsString?.split(',').filter(isPurposeName);

    return purposeNames.map((purposeName) => ({ purposeName, placeholder: localize(`PersonalizationPlaceholderPurpose${purposeName.charAt(0).toUpperCase()}${purposeName.slice(1)}`) })) ?? [];
}
