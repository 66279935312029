import {
    Box,
    Collapsible,
    CollapsibleContent,
    CollapsibleSummary,
    CollapsibleSummaryButton,
} from '@vp/swan';
import { useState } from 'react';
import { booleanRenderPropertySelector, getIsL1orL2 } from 'client/store/config/reducer';
import { useSelector } from 'react-redux';
import { Filters } from 'client/components/Gallery/Filters';
import { PersonalizationFilter } from 'client/components/Gallery/Filters/PersonalizationFilter';
import { BrandProfileProvider } from '@vp/react-brand';
import config from 'config';
import { useAuth } from '~/client/hooks/useAuth';
import { RenderProperty } from '~/shared/renderProperties';
import { SidebarQuickLinks } from 'client/components/Gallery/SidebarQuickLinks';
import { useTranslations } from 'client/hooks/useTranslations';
import { PersonalizationHeader } from './PersonalizationHeader';

export const PersonalizationSidebar = (): JSX.Element => {
    const [expanded, setExpanded] = useState(true);
    const isL1orL2 = useSelector(getIsL1orL2);
    const booleanRenderProperty = useSelector(booleanRenderPropertySelector);
    const auth = useAuth();
    const localize = useTranslations();
    const isRedirectToL0Enabled = booleanRenderProperty(RenderProperty.RedirectToL0);

    const brandAuth = auth?.authorizationHeader ? { type: '', token: auth.authorizationHeader } : '';

    const shouldRenderFilters = !isL1orL2 || isRedirectToL0Enabled;

    return (
        <div className="personalization-sidebar-container">
            {/* This may need to be moved higher in the render tree depending on mobile implementation */}
            <BrandProfileProvider
                includeSpeculations
                authorization={brandAuth}
                requestor={config.appName}
            >
                <Collapsible
                    defaultExpanded
                    className="personalization-sidebar-collapsible"
                    expanded={expanded}
                    mb={6}
                    mx={3}
                    pb={6}
                    pt={0}
                    px={4}
                    onRequestExpandedChange={(exp): void => setExpanded(exp)}
                >
                    <CollapsibleSummary className="sidebar-collapsible-summary">
                        <CollapsibleSummaryButton className="sidebar-collapsible-button" pb={7} pt={6}>
                            <PersonalizationHeader />
                        </CollapsibleSummaryButton>
                    </CollapsibleSummary>
                    <CollapsibleContent className="sidebar-collapsible-content" pb={0} pr={0}>
                        <PersonalizationFilter />
                    </CollapsibleContent>
                </Collapsible>
                <Box mx={4}>
                    <Collapsible defaultExpanded mt={0}>
                        <CollapsibleSummary className="sidebar-collapsible-summary">
                            <CollapsibleSummaryButton className="sidebar-filter-parent-button" pb={0}>{localize('PersonalizationAlternativeFiltersMenuHeader')}</CollapsibleSummaryButton>
                        </CollapsibleSummary>
                        <CollapsibleContent pr={0}>
                            {shouldRenderFilters ? <Filters /> : <SidebarQuickLinks />}
                        </CollapsibleContent>
                    </Collapsible>
                </Box>
            </BrandProfileProvider>
        </div>
    );
};

PersonalizationSidebar.displayName = 'PersonalizationSidebar';
