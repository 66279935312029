import { ReactNode } from 'react';
import { Card } from '@vp/swan';

interface TemplateTileProps {
    children: ReactNode;
}

export const TemplateTile = ({ children }: TemplateTileProps): JSX.Element => (
    <Card backgroundColor="strong" className="template-tile-wrapper" p={3} pb={6}>
        {children}
    </Card>
);
