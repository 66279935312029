import { useState } from 'react';
import {
    ModalDialog,
    ModalDialogBody,
    ModalDialogContent,
    Button,
    Icon,
    ModalDialogNav,
    ModalDialogCloseButton,
} from '@vp/swan';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { designPersonalizationContextSelector, personalizedTextSelector } from '~/client/store/personalization/selectors';
import { PersonalizationHeader } from 'client/components/Gallery/Filters/PersonalizationFilter/PersonalizationHeader';
import { PersonalizationFilter } from 'client/components/Gallery/Filters/PersonalizationFilter';

export const MobilePersonalization = (): JSX.Element | null => {
    const [isOpen, setIsOpen] = useState(false);
    const [mobileTextfieldValues, setMobileTextfieldValues] = useState<Record<string, string>>({});
    const [unappliedAsset, setUnappliedAsset] = useState<Gallery.Models.Personalization.PhotoPreviewData>();
    const dpc = useSelector(designPersonalizationContextSelector);
    const personalizedText = useSelector(personalizedTextSelector);

    const resetToTemplateData = (): void => {
        setMobileTextfieldValues(personalizedText);

        if (dpc?.images?.[0].image) {
            const {
                printUrl, previewUrl, width, height,
                // eslint-disable-next-line no-unsafe-optional-chaining
            } = dpc?.images?.[0].image;

            setUnappliedAsset({
                id: '1', thumbnailUrl: previewUrl, printUrl, previewUrl, originalSourceUrl: printUrl, width, height,
            });
        } else {
            setUnappliedAsset(undefined);
        }
    };

    const handleModalOpen = ((): void => {
        setIsOpen(true);
        resetToTemplateData();
    });

    const handleModalClose = ((): void => {
        setIsOpen(false);
        resetToTemplateData();
    });

    return (
        <>
            <Button
                aria-labelledby="personalization-button-label"
                buttonShape="round"
                className={classnames('mobile-rounded-icon-button', 'mobile-personalization-button')}
                iconPosition="left"
                size="mini"
                skin="secondary"
                onClick={handleModalOpen}
            >
                <Icon className="personalization-button-icon" iconType="magic" />
            </Button>
            <ModalDialog
                takeOver
                className="mobile-personalization-dialog"
                isOpen={isOpen}
                variant="panel-left"
                onRequestDismiss={handleModalClose}
            >
                <ModalDialogContent fullBleed aria-label="mobile-personalization-content">
                    <ModalDialogNav>
                        <ModalDialogCloseButton accessibleText="Close" />
                    </ModalDialogNav>
                    <ModalDialogBody>
                        <PersonalizationHeader />
                        <PersonalizationFilter
                            mobileProps={{
                                mobileTextfieldValues,
                                setIsOpen,
                                setMobileTextfieldValues,
                                setUnappliedAsset,
                                unappliedAsset,
                            }}
                        />
                    </ModalDialogBody>
                </ModalDialogContent>
            </ModalDialog>
        </>
    );
};

MobilePersonalization.displayName = 'MobilePersonalization';
