import {
    Fragment,
    useEffect, useMemo,
    useRef,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useRecoilState } from 'recoil';
import { RenderProperty } from 'shared/renderProperties';
import { NoResultsMessage } from 'client/components/Gallery/PreviewArea/NoResultsMessage';
import { PreviewList } from 'client/components/Gallery/PreviewArea/PreviewList';
import { useTranslations } from 'client/hooks/useTranslations';
import { tileEntityAllIdsSelector, tileEntityByIdSelector } from 'client/store/tileEntity';
import { currentPageSelector, totalEntityCountSelector } from 'client/store/paging';
import {
    WES_TENANT, DEFAULT_EAGER_IMAGES, TILE_PREVIEW_SIZE, MAXIMUM_PREVIEW_HEIGHT, MAXIMUM_PREVIEW_DEFAULT_HEIGHT,
} from 'client/constants';
import { useAuth } from 'client/hooks/useAuth';
import { useQueryFavorites } from 'client/hooks/useFavorites';
import {
    booleanRenderPropertySelector,
    getLocaleSelector,
    getProductKey,
    getMarket,
    getQuantitySelector,
    stringRenderPropertySelector,
    getViewId,
    getMpvid,
} from 'client/store/config';
import { getQuickViewId } from 'client/store/debug';
import { DesignTile } from 'client/components/common/DesignTile';
import { mapFavoritesWorkIdsToEntityIds } from '~/client/utils/mapFavoritesWorkIdsToEntityIds';
import { ComparativeNameDisplay } from 'client/components/common/ComparativeNameDisplay';
import { ColorSwatchPicker } from 'client/components/common/ColorSwatchPicker';
import { TileEntityFavorite } from 'client/components/Gallery/PreviewArea/TileEntityFavorite';
import { usePreviewDimensions } from 'client/hooks/usePreviewDimensions';
import { productOptionsByProductKeySelector } from 'client/store/productOptionsByProductKey';
import { GalleryTileEntityProvider } from 'client/components/Gallery/PreviewArea/GalleryTileEntityProvider';
import { DebugPop } from 'client/components/Gallery/PreviewArea/DebugPop';
import { useExperimentation, TRACKING_BEHAVIOR } from 'client/hooks/useExperimentation';
import { ContextualBatchPricingDisplay } from 'client/components/common/PricingDisplay/ContextualBatchPricingDisplay';
import {
    AnalyticsNames,
    ANALYTICS_EVENT_ACTIONS,
    PREVIEW_TYPE,
    ANALYTICS_LABEL,
} from 'shared/constants';
import { useAnalytics } from 'client/hooks/gallery/useAnalytics';
import { designsSelector } from 'client/store/design';
import { useFavorites } from 'client/hooks/features/useFavorites';
import { FullBleedUploadTile } from 'src/client/components/Gallery/PreviewArea/FullBleedUploadTile/FullBleedUploadTile';
import { currentFavoritesState } from '~/client/atoms/currentFavoritesAtom';
import { getEntityImpressionId } from '~/client/store/analytics/entityImpressionId';
import { generateImpressionId } from '~/client/store/analytics/reducer';
import { CareTile } from 'client/components/Gallery/PreviewArea/CareTile';
import { collectionRefinementSelector, shouldHideFbuTileSelector } from 'client/store/refinement/selectors';
import { REVISED_DESIGN_FIRST_FLOW_VARIATIONS } from '~/experiments/RevisedDesignFirstFlow/constants';
import { getTileRedirectionUrl } from '~/experiments/RevisedDesignFirstFlow/utils';
import { QuickViewOutlet } from 'client/components/Gallery/PreviewArea/QuickViewOutlet';
import { useGalleryParams } from 'client/hooks/useGalleryParams';
import { getIsLoadMoreAvailable } from '~/client/store/config/selectors';
import { DesignSkeleton } from 'client/components/Gallery/PreviewArea/DesignSkeleton';
import { useCdpPdpUrls } from '~/experiments/RevisedDesignFirstFlow/useUrlService';
import {
    MERCH_MODULES_COLLECTION, MERCH_MODULES_TILE_SIZE, MERCH_MODULES_WRAPPER_CLASS_NAME,
} from '~/experiments/MerchModules/constants';
import { MerchModules } from '~/experiments/MerchModules';
import { merchModulesTileEntityAllIdsSelector } from '~/client/store/merchModulesTileEntities';
import { currentPageInfoSelector } from '~/client/store/paging/selectors';
import { useInView } from 'react-intersection-observer';
import { useShowNewTemplateTile } from '~/client/hooks/newTemplateTile/useShowNewTemplateTile';
import { NewDesignTile } from '~/client/components/Gallery/NewDesignTile';
import { getRawExperiments } from '~/client/store/experimentation';
import { isMerchModulesEnabled } from '~/experiments/MerchModules/utils';

export const PreviewArea = (): JSX.Element => {
    const analytics = useAnalytics();
    const designs = useSelector(designsSelector);
    const resultCount = useSelector(totalEntityCountSelector);
    const localize = useTranslations();
    const isExperimentActive = useExperimentation();
    const tileEntityIds = useSelector(tileEntityAllIdsSelector);
    const merchModulesTileEntityIds = useSelector(merchModulesTileEntityAllIdsSelector);
    const productOptionsByProductKey = useSelector(productOptionsByProductKeySelector);
    const stringRenderProperty = useSelector(stringRenderPropertySelector);
    const booleanRenderProperty = useSelector(booleanRenderPropertySelector);
    const tilePreviewSize = stringRenderProperty(RenderProperty.TilePreviewSize);
    const locale = useSelector(getLocaleSelector);
    const mpvId = useSelector(getMpvid);
    const market = useSelector(getMarket);
    const auth = useAuth();
    const productKey = useSelector(getProductKey);
    const previewHeight = usePreviewDimensions();
    const quantity = useSelector(getQuantitySelector);
    const tileEntityById = useSelector(tileEntityByIdSelector);
    const currentPage = useSelector(currentPageSelector);
    const [currentFavorites, setCurrentFavorites] = useRecoilState(currentFavoritesState);
    const quickViewDesignID = useSelector(getQuickViewId);
    const { segment } = useGalleryParams();
    const navigate = useNavigate();
    const viewId = useSelector(getViewId);
    const isFirstPage = currentPage === 1;
    const shouldHideFbuTile = useSelector(shouldHideFbuTileSelector);
    const isLoadMoreAvailable = useSelector(getIsLoadMoreAvailable);
    const collectionRefinement = useSelector(collectionRefinementSelector);
    const { pageSize } = useSelector(currentPageInfoSelector);
    const rawExperiments = useSelector(getRawExperiments);
    const previewListRef = useRef<HTMLUListElement>(null);

    const isFirstPageAndTemplatesExist = isFirstPage && resultCount > 0;
    const shouldShowFbuTile = isFirstPageAndTemplatesExist && !shouldHideFbuTile;
    const shouldShowCareTile = isFirstPageAndTemplatesExist;
    const maxHeight = useMemo(
        () => (tilePreviewSize === TILE_PREVIEW_SIZE.LARGE
            ? MAXIMUM_PREVIEW_HEIGHT : MAXIMUM_PREVIEW_DEFAULT_HEIGHT),
        [tilePreviewSize],
    );

    // Render Props
    const shouldRenderNewTemplateTile = useShowNewTemplateTile();
    const showFavorites = useFavorites();
    const showComparativeNames = booleanRenderProperty(RenderProperty.ShowComparativeNames);
    const pricingPresentationType = stringRenderProperty(RenderProperty.PricingPresentationType);
    const { ref } = useInView({ threshold: 1 });

    // fetch favorites
    const { data: favoriteData } = useQueryFavorites(
        locale as i18n.Locale,
        WES_TENANT,
        auth?.canonicalId,
        auth?.accessToken,
        productKey,
        {
            enabled: showFavorites && !!auth && !!auth.canonicalId && !!auth.accessToken && !!locale,
            retry: 1,
            staleTime: Infinity,
        },
    );

    useEffect(() => {
        if (!favoriteData) {
            return;
        }
        setCurrentFavorites(mapFavoritesWorkIdsToEntityIds(tileEntityIds, favoriteData));
    }, [favoriteData, setCurrentFavorites, tileEntityIds]);

    useEffect(() => {
        if (quickViewDesignID) {
            const qvTile = tileEntityById(quickViewDesignID);

            if (qvTile) {
                const newUrl = new URL(window.location.href);

                newUrl.searchParams.delete('quickView');
                newUrl.pathname = `${newUrl.pathname}/qv/${quickViewDesignID}`;

                const qvUrl = `${newUrl.pathname}${newUrl.search}`;

                navigate(qvUrl, { replace: true });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quickViewDesignID]);

    const curryHandleOpenQuickView = (
        colorSwatchObjects: Gallery.ContentQuery.ColorSwatch[],
        currentDesignId: string,
        tileEntity: State.TileEntity,
    ) => (): void => {
        const color = designs.byId[currentDesignId].color?.split('#')[1];
        const impressionIdFactory = (id: string): string => {
            const tileImpressionId = getEntityImpressionId(id);

            return generateImpressionId(viewId, tileImpressionId);
        };

        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.GALLERY_DESIGN_ENGAGEMENT,
            eventLabel: ANALYTICS_LABEL.FLY_OUT_VIEWED,
            ...analytics.buildDesignEngagement({
                engagementAction: ANALYTICS_EVENT_ACTIONS.OPEN_QUICKVIEW,
                selectedDesign: currentDesignId,
                color,
                tileEntity,
                colorSwatchObjects,
                impressionId: impressionIdFactory,
                location: AnalyticsNames.DesignTile,
                position: tileEntity.position,
            }),
        });
    };

    const shouldShowRevisedPdp = isExperimentActive(
        REVISED_DESIGN_FIRST_FLOW_VARIATIONS.Enabled,
        TRACKING_BEHAVIOR.Suppress,
    );

    const isMerchModulesRefinementActive = collectionRefinement?.value === MERCH_MODULES_COLLECTION;
    const shouldShowMerchModules = isMerchModulesEnabled(rawExperiments)
        && isFirstPage
        && !isMerchModulesRefinementActive
        && merchModulesTileEntityIds.length === MERCH_MODULES_TILE_SIZE;

    const { data } = useCdpPdpUrls(locale, mpvId, shouldShowRevisedPdp);

    const careTilePosition = shouldShowFbuTile ? pageSize - 1 : pageSize;
    const isCareTileAtEnd = careTilePosition >= tileEntityIds.length;
    const shouldShowTileCareAtEnd = shouldShowCareTile && isCareTileAtEnd;

    useEffect(() => {
        if (shouldShowMerchModules) {
            const wrapper = previewListRef.current;

            if (!wrapper) {
                return;
            }

            const merchModules = wrapper.querySelector(`.${MERCH_MODULES_WRAPPER_CLASS_NAME}`);

            if (!merchModules) {
                return;
            }

            merchModules.classList.remove('hidden');

            const wrapperBottom = wrapper.getBoundingClientRect().bottom;
            const merchModulesBottom = merchModules.getBoundingClientRect().bottom;

            if (wrapperBottom === merchModulesBottom) {
                merchModules.classList.add('hidden');
            }
        }
    }, [tileEntityIds.length, shouldShowMerchModules]);

    const renderPreviewList = (): JSX.Element => (
        <PreviewList
            ref={previewListRef}
            tilePreviewSize={tilePreviewSize}
            {...(shouldRenderNewTemplateTile && { className: 'preview-area-indent' })}
        >
            {shouldShowFbuTile && (
                <FullBleedUploadTile
                    key="fbu-tile"
                    previewHeight={previewHeight}
                    pricingPresentationType={pricingPresentationType}
                    productOptionsByKey={productOptionsByProductKey}
                    userInteractionLocation="tile"
                />
            )}
            {tileEntityIds.map((entityId: string, index: number) => {
                const favoriteId = currentFavorites.get(entityId);
                const shouldPinCareTile = shouldShowCareTile && index === careTilePosition && !isCareTileAtEnd;

                const designTileElement = (
                    <GalleryTileEntityProvider tileEntityId={entityId}>
                        {(
                            tileEntity,
                            imageProps,
                            handleColorSwatchChange,
                            colorSwatchObjects,
                            currentColorSwatch,
                            currentDesignId,
                            onMouseEnter,
                        ): JSX.Element | null => {
                            if (shouldRenderNewTemplateTile) {
                                return (
                                    <NewDesignTile
                                        noFollow
                                        colorSwatches={colorSwatchObjects}
                                        currentDesignId={currentDesignId}
                                        entityId={entityId}
                                        favoriteId={favoriteId}
                                        handleColorSwatchChange={handleColorSwatchChange}
                                        imageProps={{
                                            ...imageProps,
                                            loading: tileEntity.position < DEFAULT_EAGER_IMAGES ? 'eager' : 'lazy',
                                        }}
                                        market={market}
                                        previewType={PREVIEW_TYPE.TILE}
                                        pricingPresentationType={pricingPresentationType}
                                        productOptionsByProductKey={productOptionsByProductKey}
                                        quantity={quantity}
                                        selectedDesignId={currentColorSwatch.designId}
                                        tileEntity={tileEntity}
                                    />
                                );
                            }

                            return (
                                <DesignTile
                                    imageProps={{
                                        ...imageProps,
                                        loading: tileEntity.position < DEFAULT_EAGER_IMAGES ? 'eager' : 'lazy',
                                    }}
                                    linkProps={segment ? {
                                        noFollow: true,
                                        state: { designId: currentDesignId },
                                        shouldShowRevisedPdp,
                                        to: shouldShowRevisedPdp ? getTileRedirectionUrl({
                                        // eslint-disable-next-line max-len
                                            designId: tileEntity.designId, colorSwatchObjects, locale, url: data, productOptions: tileEntity.productOptions, currentColorSwatch: currentColorSwatch.designId,
                                        }) : `${segment?.join('/')}/qv/${tileEntity.designId}`,
                                    } : undefined}
                                    maxHeight={maxHeight}
                                    onMouseEnter={onMouseEnter}
                                >
                                    {showFavorites && (
                                    <TileEntityFavorite
                                        colorSwatchObjects={colorSwatchObjects}
                                        entityId={entityId}
                                        favoriteId={favoriteId}
                                        previewType={PREVIEW_TYPE.TILE}
                                    />
                                    )}
                                    {showComparativeNames && (
                                    <ComparativeNameDisplay>
                                        {tileEntity.comparativeName}
                                    </ComparativeNameDisplay>
                                    )}
                                    <ContextualBatchPricingDisplay
                                        fullProductOptions={tileEntity.fullProductOptions}
                                        locale={locale as i18n.Locale}
                                        market={market}
                                        pricingPresentationType={pricingPresentationType}
                                        productKey={tileEntity.productKey}
                                        productOptionsByProductKey={productOptionsByProductKey}
                                        productOptionsHash={tileEntity.productOptionsHash}
                                        productVersion={tileEntity.productVersion}
                                        quantity={quantity}
                                    />
                                    <ColorSwatchPicker
                                        collapse
                                        colorSwatches={colorSwatchObjects}
                                        entityId={entityId}
                                        locale={locale as i18n.Locale}
                                        selectedDesignId={currentColorSwatch.designId}
                                        onColorSwatchChange={handleColorSwatchChange}
                                        onLabelClick={curryHandleOpenQuickView(
                                            colorSwatchObjects,
                                            currentDesignId,
                                            tileEntity,
                                        )}
                                    />
                                    <DebugPop entityId={tileEntity.designId} />
                                </DesignTile>
                            );
                        }}
                    </GalleryTileEntityProvider>
                );

                return (
                    <Fragment key={entityId}>
                        {designTileElement}
                        {shouldPinCareTile && <CareTile previewHeight={previewHeight} />}
                    </Fragment>
                );
            })}
            {shouldShowTileCareAtEnd && <CareTile previewHeight={previewHeight} />}
            {isLoadMoreAvailable && <DesignSkeleton height={maxHeight} />}
            <QuickViewOutlet />
            {shouldShowMerchModules && <MerchModules ref={ref} />}
        </PreviewList>
    );

    return (
        <section aria-label={localize('PreviewRegionLabel')} className="preview-area-container" id="preview-area">
            {!resultCount && (<NoResultsMessage key="no-results-message" />)}
            {!!resultCount && renderPreviewList()}
        </section>
    );
};

PreviewArea.displayName = 'PreviewArea';
