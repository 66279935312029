import { useSelector } from 'react-redux';

import { tileEntityAllIdsSelector, tileEntityByIdSelector } from 'client/store/tileEntity';
import {
    ProductStructuredDataWriter,
} from 'client/components/Gallery/ProductStructuredData/ProductStructuredDataWriter';
import { getMetadata } from 'client/store/config/reducer';
import { useMemo } from 'react';
import { PREVIEWS_AMOUNT } from './data';

export const ProductStructuredData = (): JSX.Element | null => {
    const allEntities = useSelector(tileEntityAllIdsSelector);
    const galleryMetadata = useSelector(getMetadata);
    const getEntityById = useSelector(tileEntityByIdSelector);

    const entities = useMemo(
        () => allEntities.slice(0, PREVIEWS_AMOUNT).map(getEntityById),
        [allEntities, getEntityById],
    );

    const shouldRenderWriter = !!entities?.length && galleryMetadata;

    return (shouldRenderWriter ? (
        <ProductStructuredDataWriter
            entities={entities}
            galleryMetadata={galleryMetadata}
        />
    ) : null);
};
