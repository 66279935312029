import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getBooleanFromValue } from 'shared/url';
import { photoFlyoutOpenState } from 'src/client/atoms/photoFlyoutOpenAtom';
import { useRecoilState } from 'recoil';

const PERSONALIZATION_QSP = 'personalizationFlyoutOpen';

export type UsePersonalizationFlyoutOpen = [
    personalizationFlyoutOpen: boolean,
    setPersonalizationFlyoutOpenWithEffect: (isOpen: boolean) => void,
]

export const usePersonalizationFlyoutOpen = (): UsePersonalizationFlyoutOpen => {
    const [personalizationFlyoutOpen, setPersonalizationFlyoutOpen] = useRecoilState(photoFlyoutOpenState);
    const [, setSearchParams] = useSearchParams();

    useEffect(() => {
        const openFlyoutOnMount = getBooleanFromValue(
            new URLSearchParams(window.location.search).get(PERSONALIZATION_QSP),
        );

        setPersonalizationFlyoutOpen(!!openFlyoutOnMount);
    }, [setPersonalizationFlyoutOpen]);

    const setPersonalizationFlyoutOpenWithEffect = (isOpen: boolean): void => {
        setSearchParams((params) => {
            if (isOpen) {
                params.set(PERSONALIZATION_QSP, isOpen.toString());
            } else {
                params.delete(PERSONALIZATION_QSP);
            }

            return params;
        });
        setPersonalizationFlyoutOpen(isOpen);
    };

    return [personalizationFlyoutOpen, setPersonalizationFlyoutOpenWithEffect];
};
