import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getProductOptionsHash } from 'client/utils/getProductOptionsHash';
import { filterDesignVariationToVariableSelections } from 'client/components/Gallery/PreviewArea/GalleryQuickView/utils/filterDesignVariationToVariableSelections';
import { queryDesignVariations } from 'client/queries/queryDesignVariations';

export const DESIGN_VARIATIONS_QUERY_KEY_NAME = 'DESIGN_VARIATIONS';

export const DESIGN_VARIATIONS_QUERY_SETTINGS = {
    staleTime: Infinity,
};

type ResponseDesignVariation = DesignVariations.DesignVariationResponse[];

type DesignVariationsResult = Omit<UseQueryResult<ResponseDesignVariation, Error>, 'data'> & {
    data: DesignVariations.DesignVariationsByHash;
};

export const useDesignVariations = (
    query: DesignVariations.DesignVariationsInput,
): DesignVariationsResult => {
    const queryKey: DesignVariations.QueryKey = [DESIGN_VARIATIONS_QUERY_KEY_NAME, query];
    const { data, ...rest } = useQuery(queryKey, queryDesignVariations, {
        ...DESIGN_VARIATIONS_QUERY_SETTINGS,
        refetchOnWindowFocus: false,
    });
    const designVariations = (data || []).reduce((accum, dv) => {
        const designVariationsTemplateUseCaseDict = dv.templateUseCases
            .reduce((dvAccum, { templateUseCase, parentTemplateUseCase }) => ({
                ...dvAccum,
                [parentTemplateUseCase]: templateUseCase,
            }), {} as Record<string, string>);

        const variationSelections = filterDesignVariationToVariableSelections(
            dv.productOptions,
            query.variableOptions,
            designVariationsTemplateUseCaseDict,
            dv.numberOfPlaceholders,
            query.variableAttributes,
            query.variableTemplateUseCases,
        );

        return {
            ...accum,
            [getProductOptionsHash(variationSelections)]: {
                ...dv,
                variationSelections,
            },
        };
    }, {} as DesignVariations.DesignVariationsByHash);

    return { data: designVariations, ...rest } as DesignVariationsResult;
};
