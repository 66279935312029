import { memo } from 'react';
import { useInView } from 'react-intersection-observer';
import { StickyHeader } from 'client/components/Gallery/Header/StickyHeader';
import { StaticHeader } from 'client/components/Gallery/Header/StaticHeader';
import { galleryHeaderId } from 'client/components/Gallery/Header/constants';

export const Header = memo((): JSX.Element => {
    const { ref: headerRef, inView, entry } = useInView();
    const headerIsVisible = inView || !entry;

    return (
        <div className="gallery-header" id={galleryHeaderId} ref={headerRef}>
            {!headerIsVisible && <StickyHeader />}
            {headerIsVisible && <StaticHeader />}
        </div>
    );
});

Header.displayName = 'Header';
