import { UploadsButton } from '@design-stack-vista/upload-components';
import {
    Button, Icon, Spinner,
} from '@vp/swan';
import { useTranslations } from '~/client/hooks/useTranslations';

interface PersonalizationImageButtonTypes {
    handleClearImageSelection: () => void,
    setIsError: (isError: boolean) => void,
    handleImageError: () => void,
    trackUploadClicked: () => void,
    currentImage?: Gallery.Models.Personalization.PhotoPreviewData,
    isLoadingImage: boolean,
}

export const PersonalizationImageUploadButton = (props: PersonalizationImageButtonTypes): JSX.Element => {
    const {
        handleClearImageSelection,
        setIsError,
        handleImageError,
        trackUploadClicked,
        currentImage,
        isLoadingImage,
    } = props;

    const localize = useTranslations();

    if (currentImage) {
        return (
            <div>
                <Button
                    iconPosition="left"
                    width="full-width"
                    onClick={(): void => {
                        handleClearImageSelection();
                        setIsError(false);
                    }}
                >
                    <Icon src={currentImage.thumbnailUrl} onError={handleImageError} />
                    {/* Change to {localize('PersonalizationRemoveImageButton')} when available */}
                    Remove Image
                </Button>
            </div>
        );
    }

    return (
        <UploadsButton iconPosition="left" id="personalization-upload-button" width="full-width" onClick={trackUploadClicked}>
            {isLoadingImage && (
                <>
                    <span>
                        <Spinner accessibleText="Loading..." mr="5" size="tiny" />
                    </span>
                    <span>{localize('PersonalizationUploadingLabel')}</span>
                </>
            )}
            {!isLoadingImage && (
                <>
                    <span>
                        <Icon iconType="upload" size="20p" />
                    </span>
                    <span>{localize('PersonalizationAddImageButton')}</span>
                </>
            )}
        </UploadsButton>
    );
};

PersonalizationImageUploadButton.displayName = 'PersonalizationImageUploadButton';
