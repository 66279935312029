import { Typography } from '@vp/swan';
import { useTranslations } from '~/client/hooks/useTranslations';

export const PersonalizationHeader = (): JSX.Element => {
    const localize = useTranslations();

    return (
        <div className="personalization-header">
            <Typography>
                {localize('PersonalizationFilterHeader')}
            </Typography>
            <Typography fontSize="small" fontWeight="normal">{localize('PersonalizationFilterDescription')}</Typography>
        </div>
    );
};

PersonalizationHeader.displayName = 'PersonalizationHeader';
