/* eslint-disable no-param-reassign */
import { REFINEMENT_DIMENSION } from '~/shared/constants';

export const mutateQueryStateParamsWithRefinementToAdd = (
    queryStateParams: Gallery.Models.Url.SelectQueryStateParams,
    refinementToAdd: State.Refinement[],
): void => {
    refinementToAdd.forEach((item) => {
        switch (item.dimension) {
            case (REFINEMENT_DIMENSION.ATTRIBUTE):
                queryStateParams[REFINEMENT_DIMENSION.ATTRIBUTE_PLURAL] = [
                    ...queryStateParams[REFINEMENT_DIMENSION.ATTRIBUTE_PLURAL], item.value,
                ];
                break;
            case (REFINEMENT_DIMENSION.CATEGORY):
                queryStateParams[REFINEMENT_DIMENSION.CATEGORY_PLURAL] = [
                    ...queryStateParams[REFINEMENT_DIMENSION.CATEGORY_PLURAL], item.value,
                ];
                break;
            case (REFINEMENT_DIMENSION.KEYWORD):
                queryStateParams[REFINEMENT_DIMENSION.KEYWORD] = item.value
                    ?? queryStateParams[REFINEMENT_DIMENSION.KEYWORD];
                break;
            case (REFINEMENT_DIMENSION.COLLECTION):
                queryStateParams[REFINEMENT_DIMENSION.COLLECTION] = item.value
                    ?? queryStateParams[REFINEMENT_DIMENSION.COLLECTION];
                break;
            default:
                break;
        }
    });
};

export const mutateQueryStateParamsWithRefinementToRemove = (
    queryStateParams: Gallery.Models.Url.SelectQueryStateParams,
    refinementToRemove: State.Refinement[],
): void => {
    refinementToRemove.forEach((item) => {
        switch (item.dimension) {
            case (REFINEMENT_DIMENSION.ATTRIBUTE):
                queryStateParams[REFINEMENT_DIMENSION.ATTRIBUTE_PLURAL] = queryStateParams[
                    REFINEMENT_DIMENSION.ATTRIBUTE_PLURAL
                ].filter((param) => param !== item.value);
                break;
            case (REFINEMENT_DIMENSION.CATEGORY):
                queryStateParams[REFINEMENT_DIMENSION.CATEGORY_PLURAL] = queryStateParams[
                    REFINEMENT_DIMENSION.CATEGORY_PLURAL
                ].filter((param) => param !== item.value);
                break;
            case (REFINEMENT_DIMENSION.KEYWORD):
                delete queryStateParams[REFINEMENT_DIMENSION.KEYWORD];
                break;
            case (REFINEMENT_DIMENSION.COLLECTION):
                delete queryStateParams[REFINEMENT_DIMENSION.COLLECTION];
                break;
            default:
                break;
        }
    });
};
