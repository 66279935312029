import { AnyAction } from 'redux';
import {
    DEBUG_MODE_ENABLE,
    TEMPLATE_PURPOSES_UPDATE,
    SORTING_STRATEGY_UPDATE,
    SORTING_STRATEGY_COLLAPSED_UPDATE,
} from 'client/store/debug/actions';
import { RenderProperty } from 'shared/renderProperties';
import { CONTENT_UPDATE, LOCATION_POP } from 'client/store/constants';
import { TEMPLATE_PURPOSES } from '~/shared/constants';

const initialState: State.DebugState = {
    debug: false,
    bypassApproval: false,
    lastRequestUrl: undefined,
    noCache: false,
    rankingStrategyOverride: '',
    forcedRankingStrategyOverride: '',
    useConstraints: true,
    renderPropsOverride: {} as State.RenderPropsState,
    quickViewId: '',
    useRealisationEngineService: false,
    enrich: false,
    isProduct: false,
    experienceType: '',
    personalizedText: {},
    templatePurposes: [],
    sortingStrategy: null,
    imagePlaceholderAspectRatio: null,
    imagePlaceholderAspectRatioTolerance: null,
    placeholderPurposes: [],
    isSortingFilterCollapsed: false,
    useAlternateSearchProvider: false,
    searchBackend: null,
    source: null,
    designCreationTypes: [],
    aspExperimentFlags: null,
    enableTemplateUseCases: false,
};

export function reducer(
    state: State.DebugState = initialState,
    action: AnyAction,
): State.DebugState {
    switch (action.type) {
        case DEBUG_MODE_ENABLE:
            return {
                ...state,
                debug: true,
            };
        case CONTENT_UPDATE:
            return {
                ...state,
                lastRequestUrl: action.payload.requestUrl,
            };
        case TEMPLATE_PURPOSES_UPDATE:
        case LOCATION_POP:
            return {
                ...state,
                templatePurposes: action.payload.templatePurposes,
            };
        case SORTING_STRATEGY_UPDATE:
            return {
                ...state,
                sortingStrategy: action.payload.sortingStrategy,
                rankingStrategyOverride: action.payload.rankingStrategyOverride,
                forcedRankingStrategyOverride: action.payload.forcedRankingStrategyOverride,
            };
        case SORTING_STRATEGY_COLLAPSED_UPDATE: {
            return {
                ...state,
                isSortingFilterCollapsed: !state.isSortingFilterCollapsed,
            };
        }
        default:
            return state;
    }
}

export const getLastContentRequestUrl = (state: State.GlobalState): Gallery.Models.Url.ValidParsedQsValue<string> => (state.debug.lastRequestUrl);

export const getBypassApproval = (state: State.GlobalState): Gallery.Models.Url.ValidParsedQsValue<boolean> => (state.debug.bypassApproval);

export const getDebugModeEnabled = (state: State.GlobalState): { enabled: boolean } => ({ enabled: !!state.debug.debug });

export const getNoCache = (state: State.GlobalState): Gallery.Models.Url.ValidParsedQsValue<boolean> => (state.debug.noCache);

export const getUseConstraints = (state: State.GlobalState): Gallery.Models.Url.ValidParsedQsValue<boolean> => (state.debug.useConstraints);

export const getRenderPropsOverride = (state: State.GlobalState) => (key: RenderProperty): State.RenderPropertyValue => (state.debug.renderPropsOverride[key]);

export const getRenderPropsOverrides = (state: State.GlobalState): State.RenderPropsState => (state.debug.renderPropsOverride);

export const getRankingStrategyOverride = (state: State.GlobalState): Gallery.Models.Url.ValidParsedQsValue<string> => (state.debug.rankingStrategyOverride);

export const getForcedRankingStrategyOverride = (state: State.GlobalState): Gallery.Models.Url.ValidParsedQsValue<string> => (state.debug.forcedRankingStrategyOverride);

export const getQuickViewId = (state: State.GlobalState): Gallery.Models.Url.ValidParsedQsValue<string> => (state.debug.quickViewId);

export const getEnrich = (state: State.GlobalState): Gallery.Models.Url.ValidParsedQsValue<boolean> => (state.debug.enrich);

export const getIsProduct = (state: State.GlobalState): Gallery.Models.Url.ValidParsedQsValue<boolean> => (state.debug.isProduct);

export const getUseRealisationEngineService = (state: State.GlobalState): Gallery.Models.Url.ValidParsedQsValue<boolean> => (state.debug.useRealisationEngineService);

export const getExperienceType = (state: State.GlobalState): Gallery.Models.Url.ValidParsedQsValue<string> => (state.debug.experienceType);

export const getTemplatePurposes = (state: State.GlobalState): TEMPLATE_PURPOSES[] => (state.debug.templatePurposes);

export const getImagePlaceholderAspectRatio = (state: State.GlobalState): Gallery.Models.Url.ValidParsedQsValue<number> => (state.debug.imagePlaceholderAspectRatio);

export const getImagePlaceholderAspectRatioTolerance = (state: State.GlobalState): Gallery.Models.Url.ValidParsedQsValue<number> => (state.debug.imagePlaceholderAspectRatioTolerance);

export const getPlaceholderPurposes = (state: State.GlobalState): Gallery.Models.Url.ValidParsedQsValue<string[]> => (state.debug.placeholderPurposes);

export const getSortingFilterCollapsed = (state: State.GlobalState): boolean => (state.debug.isSortingFilterCollapsed);

export const getUseAlternateSearchProvider = (state: State.GlobalState): Gallery.Models.Url.ValidParsedQsValue<boolean> => (state.debug.useAlternateSearchProvider);

export const getSearchBackend = (state: State.GlobalState): Gallery.Models.Url.ValidParsedQsValue<string> => (state.debug.searchBackend);

export const getSource = (state: State.GlobalState): Gallery.Models.Url.ValidParsedQsValue<string> => (state.debug.source);

export const getDesignCreationTypes = (state: State.GlobalState): Gallery.Models.Url.ValidParsedQsValue<string[]> => (state.debug.designCreationTypes);

export const getASPExperimentFlags = (state: State.GlobalState): Gallery.Models.Url.ASPExperimentFlags => (state.debug.aspExperimentFlags);

export const getEnableTemplateUseCases = (state: State.GlobalState): Gallery.Models.Url.ValidParsedQsValue<boolean> => (state.debug.enableTemplateUseCases);
