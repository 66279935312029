import {
    useCallback, useEffect, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { generateSrcSetWithoutCustomImages } from '~/client/components/Gallery/Header/Personalization/utils';
import { UseImageResult } from '~/client/components/Gallery/NewDesignTile/interface';
import { ImageProps } from '~/client/components/common/DesignTile';
import { resolveSrc, resolveSrcSet } from '~/client/components/common/McpImage/utils';
import { instrumenter } from 'client/utils/instrumentation';
import { useCallbackRef } from '~/client/hooks/useRef';
import { useTranslations } from '~/client/hooks/useTranslations';
import { getLogger } from '~/client/utils/gallery/logger';
import { useDebouncedCallback } from 'use-debounce';
import { RenderProperty } from 'shared/renderProperties';
import { stringRenderPropertySelector } from '~/client/store/config/reducer';
import { TILE_PREVIEW_SIZE } from '~/client/constants';

export const useImage = (imageProps: ImageProps): UseImageResult => {
    const localize = useTranslations();
    const stringRenderProperty = useSelector(stringRenderPropertySelector);
    const tilePreviewSize = stringRenderProperty(RenderProperty.TilePreviewSize);
    const isLargePreview = tilePreviewSize === TILE_PREVIEW_SIZE.LARGE;

    const {
        previewsUrls,
        alt = '',
        loading = 'lazy',
        srcSet,
        bypassedApproval,
    } = imageProps;

    const imageSrc = resolveSrc(previewsUrls?.size1x) || '';
    const imageSrcSet = resolveSrcSet(previewsUrls, srcSet) || '';

    const [currentSrcSet, setCurrentSrcSet] = useState<string>(imageSrcSet);
    const [isLoading, setIsLoading] = useState<boolean>(loading === 'lazy');
    const [showLoader, setShowLoader] = useState<boolean>(true);

    const debouncedShowLoader = useDebouncedCallback(() => {
        setShowLoader(isLoading);
    }, 100);

    const imgRef = useCallbackRef<HTMLImageElement>(null, (current) => {
        setIsLoading(!current?.complete);
    });

    useEffect(() => {
        const newSrcSet = resolveSrcSet(previewsUrls, srcSet) || '';

        if (newSrcSet !== currentSrcSet) {
            try {
                if (currentSrcSet !== undefined && currentSrcSet === generateSrcSetWithoutCustomImages(newSrcSet)) {
                    return;
                }
            } catch (e) {
                getLogger().error(`Error reacting to src changes: ${e}`, e as Error, { currentSrcSet, newSrcSet });
                return;
            }
            setIsLoading(true);
            setCurrentSrcSet(newSrcSet);
        }
    }, [currentSrcSet, previewsUrls, srcSet]);

    useEffect(() => {
        if (isLoading) {
            debouncedShowLoader();
        } else {
            setShowLoader(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);

    const onError = useCallback((): void => {
        // Do not log image failures when the query string bypassApproval is true
        if (!bypassedApproval) {
            instrumenter.recordImageFailure({
                srcSet,
                ...previewsUrls,
            });
        }

        const newSrcSet = generateSrcSetWithoutCustomImages(currentSrcSet);

        if (newSrcSet) {
            setCurrentSrcSet(newSrcSet);
        }

        // checking for newSrcSet here prevents the blue triangle from being rendered and causing tiles to change height
        // newSrcSet should always be undefined outside the Photo Personalization test
        setIsLoading(false);
    }, [bypassedApproval, currentSrcSet, srcSet, previewsUrls]);

    const onLoad = (): void => {
        setIsLoading(false);
    };

    const showSpinner = loading === 'lazy' && showLoader && isLoading;

    return {
        imgRef,
        showSpinner,
        alt,
        imageSrc,
        imageSrcSet,
        loading,
        onLoad,
        onError,
        accessibilityText: localize('Loading'),
        isLargePreview,
    };
};
