import { useDispatch, useSelector } from 'react-redux';
import {
    ClearSelectionButtons, VisuallyHidden, Button, ColorSwatch, Typography,
} from '@vp/swan';

import { ClearAllRefinementsButton } from 'client/components/common/ClearAllRefinementsButton';
import { findAllRemovableRefinements } from 'client/components/Gallery/Subheader/RefinementsList/findAllRemovableRefinements';
import { refinementRemove, buildRefinement } from 'client/store/refinement';
import { useTranslations } from 'client/hooks/useTranslations';
import { REFINEMENT_DIMENSION } from '~/shared/constants';
import { useMemo } from 'react';
import { booleanRenderPropertySelector, getIsL1orL2 } from 'client/store/config/reducer';
import { MERCH_MODULES_COLLECTION } from '~/experiments/MerchModules/constants';
import { useRedirectToL0 } from '~/client/hooks/RedirectToL0/useRedirectToL0';
import { RenderProperty } from 'shared/renderProperties';

export const RefinementsList = (): JSX.Element => {
    const refinements = useSelector(findAllRemovableRefinements);
    const isL1orL2 = useSelector(getIsL1orL2);
    const booleanRenderProperty = useSelector(booleanRenderPropertySelector);
    const redirectToL0 = useRedirectToL0();

    const dispatch = useDispatch();

    const isRedirectToL0Enabled = booleanRenderProperty(RenderProperty.RedirectToL0);
    const shouldRenderClearAllButton = !isL1orL2 || isRedirectToL0Enabled;

    const refinementList = useMemo(
        () => (isRedirectToL0Enabled
            ? Object.values(refinements)
            : Object.values(refinements).filter(({ dimension }) => !isL1orL2
                || dimension === REFINEMENT_DIMENSION.KEYWORD
                || dimension === REFINEMENT_DIMENSION.COLLECTION)),
        [refinements, isL1orL2, isRedirectToL0Enabled],
    );

    const hasRefinements = !!refinementList.length;
    const localize = useTranslations();

    const curryClearRefinement = (refinementItem: Refinements.RefinementItem) => (
        event: React.MouseEvent<HTMLButtonElement>,
    ): void => {
        event.preventDefault();

        const {
            value,
            dimension,
        } = refinementItem;

        const refinementsToRemove = [buildRefinement(value, dimension)];

        if (redirectToL0([], refinementsToRemove)) {
            return;
        }

        dispatch(refinementRemove(refinementsToRemove));
    };

    const getRefinementTitle = (refinement: Refinements.RefinementItem): string => {
        const isMerchModulesRefinement = refinement.dimension === REFINEMENT_DIMENSION.COLLECTION
            && refinement.value === MERCH_MODULES_COLLECTION;

        if (isMerchModulesRefinement) {
            return localize('MerchModulesDesignersPick');
        }

        return refinement.title;
    };

    return (
        <>
            <VisuallyHidden as="h3">{localize('FiltersRegionLabel')}</VisuallyHidden>
            <ClearSelectionButtons aria-label={localize('AppliedFiltersRegionLabel')} className="refinements-list" role="region">
                {hasRefinements && (
                    <>
                        {refinementList.map((refinement) => (
                            <Button
                                aria-label={localize('ClearRefinementSelection')}
                                className="refinement-item-button"
                                key={refinement.value}
                                skin="clear-selection"
                                value={refinement.value}
                                onClick={curryClearRefinement(refinement)}
                            >
                                {refinement.value.startsWith('designcolor')
                                    && (
                                        <ColorSwatch
                                            primaryColor={refinement.value.split('designcolor_')[1] === 'cream'
                                                ? '#FBEED3'
                                                : refinement.value.split('designcolor_')[1]}
                                        />
                                    )}
                                <Typography fontWeight="bold">
                                    {getRefinementTitle(refinement)}
                                </Typography>
                            </Button>
                        ))}
                        {shouldRenderClearAllButton && <ClearAllRefinementsButton refinements={refinements} />}
                    </>
                )}
            </ClearSelectionButtons>
        </>
    );
};

RefinementsList.displayName = 'RefinementsList';
