export const designPersonalizationContextSelector = (
    state: State.GlobalState,
): Gallery.Models.Personalization.DesignPersonalizationContext => state.personalization.designPersonalizationContext;

export const photoPreviewDataSelector = (
    state: State.GlobalState,
): Gallery.Models.Personalization.PhotoPreviewData[] => state.personalization.photoPreviews;

export const personalizedTextSelector = (
    state: State.GlobalState,
): Gallery.Models.Personalization.PersonalizedTextData => state.personalization.personalizedTextData;
