import deepequals from 'fast-deep-equal/es6/index';
import {
    memo, useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion } from '@vp/swan';
import { Filter } from 'client/components/Gallery/Filters/Filter';
import {
    selectFilters,
    selectFilterExpandedState,
    setExpandedForFilter,
} from 'client/store/filter';
import { useTranslations } from 'client/hooks/useTranslations';
import {
    PERSONALIZATION_UX_MOBILE_VARIATIONS,
    PERSONALIZATION_UX_HOLIDAY_EXPANSION_VARIATIONS,
    PERSONALIZATION_UX_PHASE_2_VARIATIONS,
    PERSONALIZATION_UX_SIGNAGE_STICKER_EXPANSION_VARIATIONS,
    PERSONALIZATION_UX_VARIATIONS,
} from '~/experiments/tilePersonalization/constants';
import { TRACKING_BEHAVIOR, useExperimentation } from '~/client/hooks/useExperimentation';
import { isMobile } from '~/client/utils/deviceDetection';
import { booleanRenderPropertySelector } from '~/client/store/config/reducer';
import { RenderProperty } from 'shared/renderProperties';

export const Filters = memo((): JSX.Element => {
    const dispatch = useDispatch();
    const filters = useSelector(selectFilters, deepequals);
    const filterExpandedMap = useSelector(selectFilterExpandedState, deepequals);
    const localize = useTranslations();
    const booleanRenderProperty = useSelector(booleanRenderPropertySelector);

    const handleExpandChange = (collapsibleId: string, expanded: boolean): void => {
        dispatch(setExpandedForFilter(collapsibleId, expanded));
    };
    const isExperimentActive = useExperimentation();
    const isPersonalizationSidebarEnabled = isExperimentActive(
        PERSONALIZATION_UX_VARIATIONS.Enabled,
        TRACKING_BEHAVIOR.Suppress,
    ) || isExperimentActive(
        PERSONALIZATION_UX_PHASE_2_VARIATIONS.Enabled,
        TRACKING_BEHAVIOR.Suppress,
    ) || isExperimentActive(
        PERSONALIZATION_UX_HOLIDAY_EXPANSION_VARIATIONS.Enabled,
        TRACKING_BEHAVIOR.Suppress,
    ) || isExperimentActive(
        PERSONALIZATION_UX_SIGNAGE_STICKER_EXPANSION_VARIATIONS.Enabled,
        TRACKING_BEHAVIOR.Suppress,
    ) || booleanRenderProperty(RenderProperty.ShowPersonalizationUI);

    const isMobilePersonalizationEnabled = isExperimentActive(
        PERSONALIZATION_UX_MOBILE_VARIATIONS.Enabled,
        TRACKING_BEHAVIOR.Suppress,
    );

    const [updatedFilters, setUpdatedFilters] = useState(
        isPersonalizationSidebarEnabled || isMobilePersonalizationEnabled ? null : filters,
    );

    // Put in a useEffect so it only runs client side, isMobile doesn't work server side
    useEffect(() => {
        if ((!isMobile() && isPersonalizationSidebarEnabled) || (isMobile() && isMobilePersonalizationEnabled)) {
            // When personalization UI is enabled on desktop, move color to the first filter position
            setUpdatedFilters([
                ...filters.filter((obj) => obj.type === 'color'),
                ...filters.filter((obj) => obj.type !== 'color'),
            ]);
        } else {
            setUpdatedFilters(filters);
        }
    }, [filters, isMobilePersonalizationEnabled, isPersonalizationSidebarEnabled]);

    return (
        <section aria-label={localize('AvailableFiltersRegionLabel')} className="filter-container">
            <Accordion
                expandedCollapsibles={filterExpandedMap}
                onRequestExpandedChange={handleExpandChange}
            >
                {updatedFilters?.filter((f) => !f.hidden).map((f: State.Filter) => <Filter filter={f} key={f.name} />)}
            </Accordion>
        </section>
    );
});

Filters.displayName = 'Filters';
