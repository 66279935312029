/* eslint-disable no-underscore-dangle */
import {
    BrowserRouter, Routes, unstable_HistoryRouter as HistoryRouter,
} from 'react-router-dom';
import { history as galleryHistory } from 'client/utils/history';
import { App } from './App';

export const Router = (): JSX.Element => {
    const messages = (window as any).GALLERY.__I18N__;
    const sitewidePromobarConfig = (window as any).GALLERY.__SITEWIDE_PROMOBAR_CONFIG__;

    const routes = (
        <Routes>
            {App({
                messages, sitewidePromobarConfig,
            })}
        </Routes>
    );

    return (
        <>
            {galleryHistory && <HistoryRouter history={galleryHistory as any}>{routes}</HistoryRouter>}
            {!galleryHistory && <BrowserRouter>{routes}</BrowserRouter>}
        </>
    );
};
