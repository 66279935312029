import {
    ColorSwatch, SelectionSetInput, SelectionSetLabel,
} from '@vp/swan';
import { TemplateTileColorSwatchProps } from '~/client/components/common/TemplateTile/components/TemplateTileColorSwatch/interface';

export const TemplateTileColorSwatch = ({ color, designId, title }: TemplateTileColorSwatchProps): JSX.Element => (
    <SelectionSetInput value={designId}>
        <SelectionSetLabel>
            <ColorSwatch accessibleText={title} primaryColor={color} />
        </SelectionSetLabel>
    </SelectionSetInput>
);
