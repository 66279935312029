import { AnyAction } from 'redux';
import {
    CUSTOM_IMAGES_UPDATE, LOGO_APPLIED_UPDATE, PERSONALIZED_TEXT_UPDATE,
} from './reducer';

export const logoAppliedUpdate = (logoApplied: boolean): AnyAction => ({
    type: LOGO_APPLIED_UPDATE,
    payload: {
        logoApplied,
    },
});

export const personalizedTextUpdate = (text: Gallery.Models.Personalization.PersonalizedTextData): AnyAction => ({
    type: PERSONALIZED_TEXT_UPDATE,
    payload: { text },
});

export const customImageDataUpdate = (images: Gallery.Models.Personalization.CustomImageData[]): AnyAction => ({
    type: CUSTOM_IMAGES_UPDATE,
    payload: { images },
});
