export const personalizedTextToDpcText = (
    personalizedText: Gallery.Models.Personalization.PersonalizedTextData,
): Gallery.Models.Personalization.DpcText[] | undefined => {
    const dpcTextArray: Gallery.Models.Personalization.DpcText[] = Object.keys(personalizedText).map((key) => ({
        textField: {
            purposeName: key,
            text: personalizedText[key],
        },
    }));

    return dpcTextArray.length > 0 ? dpcTextArray : undefined;
};

export const customImagesToDpcImages = (
    customImages: Gallery.Models.Personalization.CustomImageData[],
): Gallery.Models.Personalization.DpcImage[] | undefined => {
    const dpcImageArray: Gallery.Models.Personalization.DpcImage[] = customImages.map((image) => ({
        image: {
            originalSourceUrl: image.originalSourceUrl,
            previewUrl: image.previewUrl,
            printUrl: image.printUrl,
            width: image.width,
            height: image.height,
        },
        // TODO QSP-673: derive this parameter from imagemind rather than setting manually
        purpose: 'logo',
    }));

    return dpcImageArray.length > 0 ? dpcImageArray : undefined;
};

interface buildDpcArgs {
    text?: Gallery.Models.Personalization.DpcText[],
    images?: Gallery.Models.Personalization.DpcImage[],
}

export const buildDesignPersonalizationContext = ({ text, images }: buildDpcArgs): Gallery.Models.Personalization.DesignPersonalizationContext => {
    if (!text && !images) {
        return undefined;
    }
    const newContext: Gallery.Models.Personalization.DesignPersonalizationContext = {
        designPersonalizationContextVersion: 1,
    };

    if (text) {
        newContext.text = text;
    }

    if (images) {
        newContext.images = images;
    }

    return newContext;
};
