import { ComponentProps } from 'react';
import {
    SelectionSet,
    SelectionSetInput,
    SelectionSetLabel,
} from '@vp/swan';

import { FilterOption } from 'client/components/Gallery/Filters/FilterOption';
import {
    buildRefinement, refinementAddAndRemove,
} from 'client/store/refinement';
import { ANALYTICS_EVENT_ACTIONS, REFINEMENT_DIMENSION } from 'shared/constants';
import { useDispatch, useSelector } from 'react-redux';
import { scrollUpToElement } from 'client/utils/scrollToElement';
import { galleryHeaderId } from 'client/components/Gallery/Header/constants';
import { isFilterModalHidden } from 'client/components/Gallery/Header/FilterMenu/utils';
import { useAnalytics } from '~/client/hooks/gallery/useAnalytics';
import { TRACKING_BEHAVIOR, useExperimentation } from '~/client/hooks/useExperimentation';
import { getRawExperiments } from '~/client/store/experimentation';
import {
    PERSONALIZATION_UX_VARIATIONS,
    PERSONALIZATION_UX_EXPERIMENT_NAME,
    PERSONALIZATION_UX_PHASE_2_VARIATIONS,
    PERSONALIZATION_UX_PHASE_2_EXPERIMENT_NAME,
    PERSONALIZATION_UX_HOLIDAY_EXPANSION_VARIATIONS,
    PERSONALIZATION_UX_HOLIDAY_EXPANSION_EXPERIMENT_NAME,
    PERSONALIZATION_UX_SIGNAGE_STICKER_EXPANSION_VARIATIONS,
    PERSONALIZATION_UX_SIGNAGE_STICKER_EXPANSION_EXPERIMENT_NAME,
} from '~/experiments/tilePersonalization/constants';
import { trackExperimentEngagement } from '~/shared/ab-testing';
import { isMobile } from '~/client/utils/deviceDetection';
import { useRedirectToL0 } from '~/client/hooks/RedirectToL0/useRedirectToL0';

export interface PropTypes {
    options: RenderableFilterOption[];
    title: string;
    refinementDimension: REFINEMENT_DIMENSION;
    SelectionSetProps?: Omit<ComponentProps<typeof SelectionSet>, 'defaultSelectedValue' | 'variant' | 'selectedValue' | 'selectedValues' | 'onSelectedValueChange' | 'onSelectedValuesChange' | 'skin'>;
    selectedValues: SelectedValues;
    shouldShowFilterOption?: (o: RenderableFilterOption) => boolean;
}

export const FilterCheckboxList = (props: PropTypes): JSX.Element => {
    const {
        options,
        title,
        refinementDimension,
        SelectionSetProps,
        selectedValues,
        shouldShowFilterOption = (): boolean => true,
    } = props;

    const dispatch = useDispatch();
    const redirectToL0 = useRedirectToL0();

    // hooks added for temporary Personalization UX experiment tracking
    const isExperimentActive = useExperimentation();
    const rawExperiments = useSelector(getRawExperiments);
    const analytics = useAnalytics();

    const handleSelectedValuesChange = (newSelectedValues: SelectedValues): void => {
        // Personalization UX test is desktop only
        if (!isMobile()) {
            if (isExperimentActive(PERSONALIZATION_UX_VARIATIONS.Enabled, TRACKING_BEHAVIOR.Suppress)) {
                trackExperimentEngagement(
                    PERSONALIZATION_UX_VARIATIONS.Enabled,
                    rawExperiments,
                    analytics,
                    ANALYTICS_EVENT_ACTIONS.GALLERY_FILTER_ENGAGEMENT,
                    'Filter Update',
                    PERSONALIZATION_UX_EXPERIMENT_NAME,
                    title,
                );
            } else if (isExperimentActive(PERSONALIZATION_UX_VARIATIONS.Control, TRACKING_BEHAVIOR.Suppress)) {
                trackExperimentEngagement(
                    PERSONALIZATION_UX_VARIATIONS.Control,
                    rawExperiments,
                    analytics,
                    ANALYTICS_EVENT_ACTIONS.GALLERY_FILTER_ENGAGEMENT,
                    'Filter Update',
                    PERSONALIZATION_UX_EXPERIMENT_NAME,
                    title,
                );
            } if (isExperimentActive(PERSONALIZATION_UX_PHASE_2_VARIATIONS.Enabled, TRACKING_BEHAVIOR.Suppress)) {
                trackExperimentEngagement(
                    PERSONALIZATION_UX_PHASE_2_VARIATIONS.Enabled,
                    rawExperiments,
                    analytics,
                    ANALYTICS_EVENT_ACTIONS.GALLERY_FILTER_ENGAGEMENT,
                    'Filter Update',
                    PERSONALIZATION_UX_PHASE_2_EXPERIMENT_NAME,
                    title,
                );
            } else if (isExperimentActive(PERSONALIZATION_UX_PHASE_2_VARIATIONS.Control, TRACKING_BEHAVIOR.Suppress)) {
                trackExperimentEngagement(
                    PERSONALIZATION_UX_PHASE_2_VARIATIONS.Control,
                    rawExperiments,
                    analytics,
                    ANALYTICS_EVENT_ACTIONS.GALLERY_FILTER_ENGAGEMENT,
                    'Filter Update',
                    PERSONALIZATION_UX_PHASE_2_EXPERIMENT_NAME,
                    title,
                );
            } else if (
                isExperimentActive(PERSONALIZATION_UX_HOLIDAY_EXPANSION_VARIATIONS.Enabled, TRACKING_BEHAVIOR.Suppress)
            ) {
                trackExperimentEngagement(
                    PERSONALIZATION_UX_HOLIDAY_EXPANSION_VARIATIONS.Enabled,
                    rawExperiments,
                    analytics,
                    ANALYTICS_EVENT_ACTIONS.GALLERY_FILTER_ENGAGEMENT,
                    'Filter Update',
                    PERSONALIZATION_UX_HOLIDAY_EXPANSION_EXPERIMENT_NAME,
                    title,
                );
            } else if (
                isExperimentActive(PERSONALIZATION_UX_HOLIDAY_EXPANSION_VARIATIONS.Control, TRACKING_BEHAVIOR.Suppress)
            ) {
                trackExperimentEngagement(
                    PERSONALIZATION_UX_HOLIDAY_EXPANSION_VARIATIONS.Control,
                    rawExperiments,
                    analytics,
                    ANALYTICS_EVENT_ACTIONS.GALLERY_FILTER_ENGAGEMENT,
                    'Filter Update',
                    PERSONALIZATION_UX_HOLIDAY_EXPANSION_EXPERIMENT_NAME,
                    title,
                );
            } else if (
                isExperimentActive(
                    PERSONALIZATION_UX_SIGNAGE_STICKER_EXPANSION_VARIATIONS.Enabled,
                    TRACKING_BEHAVIOR.Suppress,
                )
            ) {
                trackExperimentEngagement(
                    PERSONALIZATION_UX_SIGNAGE_STICKER_EXPANSION_VARIATIONS.Enabled,
                    rawExperiments,
                    analytics,
                    ANALYTICS_EVENT_ACTIONS.GALLERY_FILTER_ENGAGEMENT,
                    'Filter Update',
                    PERSONALIZATION_UX_SIGNAGE_STICKER_EXPANSION_EXPERIMENT_NAME,
                    title,
                );
            } else if (
                isExperimentActive(
                    PERSONALIZATION_UX_SIGNAGE_STICKER_EXPANSION_VARIATIONS.Control,
                    TRACKING_BEHAVIOR.Suppress,
                )
            ) {
                trackExperimentEngagement(
                    PERSONALIZATION_UX_SIGNAGE_STICKER_EXPANSION_VARIATIONS.Control,
                    rawExperiments,
                    analytics,
                    ANALYTICS_EVENT_ACTIONS.GALLERY_FILTER_ENGAGEMENT,
                    'Filter Update',
                    PERSONALIZATION_UX_SIGNAGE_STICKER_EXPANSION_EXPERIMENT_NAME,
                    title,
                );
            }
        }

        const toAdd: State.Refinement[] = [];
        const toRemove: State.Refinement[] = [];

        for (const [value, isSelected] of Object.entries(newSelectedValues)) {
            const refinement = buildRefinement(value, refinementDimension);

            (isSelected ? toAdd : toRemove).push(refinement);
        }

        if (redirectToL0(toAdd, toRemove)) {
            return;
        }

        dispatch(refinementAddAndRemove(toAdd, toRemove));
        if (isFilterModalHidden()) {
            scrollUpToElement(galleryHeaderId);
        }
    };

    return (
        <SelectionSet
            aria-label={title}
            className="selection-set-layout-override"
            id={`selection-set-${title}`}
            selectedValues={selectedValues}
            skin="simple-column"
            variant="multi-select"
            onSelectedValuesChange={handleSelectedValuesChange}
            {...SelectionSetProps}
        >
            {options.filter(shouldShowFilterOption)
                .map((o) => (
                    <div className="selection-set-item" key={o.value}>
                        <SelectionSetInput
                            disabled={o.disabled}
                            value={o.value}
                        >
                            <SelectionSetLabel>
                                <FilterOption facetCount={o.facetCount} title={o.title} />
                            </SelectionSetLabel>
                        </SelectionSetInput>
                    </div>
                ))}
        </SelectionSet>
    );
};

FilterCheckboxList.displayName = 'FilterCheckboxList';
